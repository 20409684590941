<script>

export default {
  components: {},
  methods: {},
  props: {
    eid:Number,
    mode: String
  },
  data() {
    return {
      dialog: false,
      eventName: 'TestEvent'
    }
  }
}
</script>
<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="50vw"
  >
    <template v-slot:activator="{ props }">
      <v-btn v-if="mode==='btn'"
          density="comfortable"
          v-bind="props"
          variant="outlined"
      > Duty Roster
      </v-btn>
      <v-btn v-if="mode==='icon'"
             density="comfortable"
             v-bind="props"
             variant="flat"
             icon="mdi-clipboard-text"
      >
      </v-btn>
    </template>
    <v-card theme="light">
      <v-card-title class="text-left ma-3">
        <span class="text-h5">{{ eventName }} - Duty Log</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-table>
            <thead>
            <tr>
              <th class="text-left">
                User name
              </th>
              <th class="text-left">
                Email
              </th>
              <th class="text-left">
                Position
              </th>
              <th class="text-left">
                Agency
              </th>
              <th class="text-left">
                Start
              </th>
              <th class="text-left">
                End
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>John Doe</td>
              <td>john.doe@gmail.com</td>
              <td>Position</td>
              <td>Agency</td>
              <td>17/03/2023</td>
              <td>23/08/2023</td>
              <td>
                <v-btn
                    density="compact"
                    icon="mdi-file-multiple"
                    variant="flat"></v-btn>
              </td>
            </tr>
            <tr>
              <td>Jon Dou</td>
              <td>john.doe@gmail.com</td>
              <td>Position</td>
              <td>Agency</td>
              <td>17/03/2023</td>
              <td>23/08/2023</td>
              <td>
                <v-btn
                    density="compact"
                    icon="mdi-file-multiple"
                    variant="flat"></v-btn>
              </td>
            </tr>
            <tr>
              <td>John Doe</td>
              <td>john.doe@gmail.com</td>
              <td>Position</td>
              <td>Agency</td>
              <td>17/03/2023</td>
              <td>23/08/2023</td>
              <td>
                <v-btn
                    density="compact"
                    icon="mdi-file-multiple"
                    variant="flat"></v-btn>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
            class="ma-3 ml-8"
            size="large"
            variant="outlined"
            @click="dialog = false"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            class="ma-3 mr-8"
            size="large"
            variant="outlined"
            @click="dialog=false"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
</style>