<script>
import {mapGetters} from "vuex";
import {uploadAttachmentName, saveData} from "@/services.js";

export default {
  methods: {
    async saveAndClose() {
      try {
        if (this.rfile && this.rfile.length > 0) {
          const fd = new FormData() ;
          let newname = await uploadAttachmentName(this.rfile[0])
          if (!!newname) {
            fd.append("owner", this.fowner);
            fd.append('title', this.ftitle);
            fd.append('visibility', this.selectedMunis);
            fd.append('mode', 'saverepofile');
          } else {
            alert('Something went wrong in document upload');
          }
          let result1 = await saveData(fd);
          if (!!result1['Status']) {
            if (result1['Status']==='OK') {
              this.closeDlg();
            }
          }
        }
      } catch(error) {
        alert('Something went wrong in saving document');
      }
    },
    closeDlg: function() {
      this.dialog = false ;
    },
    selectionChanged: function () {
      console.log(JSON.stringify(this.selectedMunis))
    },
  },
  /*
  recipientsA :[{"CountyState":"(SALEM County, NJ)","Code":34033,"Name":"Salem County","group":"Self"},
    {"CountyState":"(SALEM County, NJ)","Code":-34033,"Name":"All Municipalities","group":"Self"},
    {"CountyState":"(SALEM County, NJ)","Code":99999,"Name":"EPZ Towns","group":"Self"},
    {"CountyState":"(ATLANTIC County, NJ)","Code":34001,"Name":"Atlantic County","group":"Neighbours"},
    {"CountyState":"(CUMBERLAND County, NJ)","Code":34011,"Name":"Cumberland County","group":"Neighbours"},
    {"CountyState":"(GLOUCESTER County, NJ)","Code":34015,"Name":"Gloucester County","group":"Neighbours"},
    {"CountyState":"(SALEM County, NJ)","Code":882131,"Name":"Alloway Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882135,"Name":"Carneys Point Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":885206,"Name":"Elmer Borough","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882064,"Name":"Elsinboro Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882065,"Name":"Lower Alloways Creek Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882133,"Name":"Mannington Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882136,"Name":"Oldmans Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":885348,"Name":"Penns Grove Borough","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882134,"Name":"Pennsville Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882132,"Name":"Pilesgrove Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":1729723,"Name":"Pittsgrove Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882130,"Name":"Quinton Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":885385,"Name":"Salem City","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":1723212,"Name":"Upper Pittsgrove Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":885452,"Name":"WoodsTown Borough","group":"Municipalities"}],

  recipientsB :[{"CountyState":"(SALEM County, NJ)","Code":-34033,"Name":"All Municipalities","group":"Self"},
    {"CountyState":"(SALEM County, NJ)","Code":99999,"Name":"EPZ Towns","group":"Self"},
    {"CountyState":"(ATLANTIC County, NJ)","Code":34001,"Name":"Atlantic County","group":"Neighbours"},
    {"CountyState":"(CUMBERLAND County, NJ)","Code":34011,"Name":"Cumberland County","group":"Neighbours"},
    {"CountyState":"(GLOUCESTER County, NJ)","Code":34015,"Name":"Gloucester County","group":"Neighbours"},
    {"CountyState":"(SALEM County, NJ)","Code":882131,"Name":"Alloway Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882135,"Name":"Carneys Point Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":885206,"Name":"Elmer Borough","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882064,"Name":"Elsinboro Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882065,"Name":"Lower Alloways Creek Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882133,"Name":"Mannington Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882136,"Name":"Oldmans Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":885348,"Name":"Penns Grove Borough","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882134,"Name":"Pennsville Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882132,"Name":"Pilesgrove Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":1729723,"Name":"Pittsgrove Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":882130,"Name":"Quinton Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":885385,"Name":"Salem City","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":1723212,"Name":"Upper Pittsgrove Township","group":"Municipalities"},
    {"CountyState":"(SALEM County, NJ)","Code":885452,"Name":"WoodsTown Borough","group":"Municipalities"}],
*/
  data: () => ({
    fowner: null,
    repotype: null,
    ftitle: null,
    rev: null,
    rfile: null,
    ddate: null,
    dialog: false,
    selectedMunis: null,
  }),
  computed: {
    ...mapGetters(['getRecipientsA']),
    muniList() {
      let munilist = [];
      this.getRecipientsA.forEach( rec => {
        if (rec['group']!=='Neighbours') {
          munilist.push({'l': rec.Name, 'v': rec.Code});
        }
      })
      return munilist;
    },
    notEnoughData() {
      let flag = this.fowner && this.ftitle && this.rev && this.ddate;
      flag = flag && this.rfile && (this.rfile.length>0);
      flag = flag && this.selectedMunis && (this.selectedMunis.length>0)
      return !flag;
    },
    concatenatedSelectedItems() {
      return this.selectedMunis
          .map((v) => this.muniList.find((item) => item.v === v).l)
          .join(', ');
    },
  }
}
</script>

<template>
    <v-dialog
        theme="light"
        v-model="dialog"
        persistent
        width="30vw"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            variant="flat"
            icon = "mdi-plus"
            size="small"
            density="compact"
            v-bind="props"
        >
        </v-btn>
        <!--
        <v-btn
            append-icon="mdi-paperclip"
            density="default"
            v-bind="props"
            variant="outlined"
        > Add new document
        </v-btn>
        <v-spacer></v-spacer>
        -->
      </template>
      <v-card>
        <v-card-title class="text-center mt-3">New Dashboard Link</v-card-title>
        <v-card-text>
          <v-row class="pa-0">
            <v-col
                class="pa-0"
                cols="12">
              <v-text-field
                  density="compact"
                  label="Link Title"
                  variant="outlined"
                  v-model="fowner"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col
                class="pa-0"
                cols="12">
              <v-text-field
                  v-model="ftitle"
                  density="compact"
                  label="Link Location"
                  variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col
                class="pa-0"
                cols="12">
            <v-autocomplete
                :items="muniList"
                item-title="l"
                item-value="v"
                class="mr-2"
                density="compact"
                label="Select jurisdiction"
                multiple
                variant="outlined"
                hide-details
                v-model="selectedMunis"
                @update:model-value="selectionChanged"
            >
              <template v-slot:selection="{ item, index }">
                <div v-if="index===0" class="custom-selection">
                  {{ concatenatedSelectedItems }}
                </div>
              </template>
            </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="ma-1 pa-0"
              variant="outlined"
              width="30%"
              @click="closeDlg"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-1 pa-0"
              variant="outlined"
              width="30%"
              :disabled="notEnoughData"
              @click="saveAndClose"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<style scoped>
.custom-selection {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>