<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      eventName: '',
      startDate: null,
      endDate: null,
      showStartDatePicker: null,
      showEndDatePicker: null,
      selectedEvent: null,
      startTime: null,
      endTime: null,
      loggedas: null,
      agency: null,
      position: null,
      reliefName: null,
      reliefTime: null,
      name: null,
      resAssgn: [
        {'ra': null, icsp: null, ha: null}
      ]
    }
  },
  computed: {
    formattedStartDate() {
      return this.startDate ? this.formatDate(this.startDate) : '';
    },
    formattedEndDate() {
      return this.endDate ? this.formatDate(this.endDate) : '';
    },
    eventList() {
      const evx = [];
      this.$store.state.events.forEach( ev => {
        if (ev['status']===1 || ev['status']===2) {
          evx.push({'l': ev['ename'], 'v': ev['id']});
        }
      })
      return evx;
    },
    userName() {
      return this.$store.state.loggedUserObj.FIRST_NAME + ' ' + this.$store.state.loggedUserObj.LAST_NAME
    }
  },
  methods: {
    saveICS() {
      const ename = this.eventList.find(obj => obj.v === this.selectedEvent)?.l;
      this.$store.commit('setICSEvent', this.selectedEvent, ename) ;
      localStorage.setItem('ICSEventID', this.selectedEvent);
      localStorage.setItem('ICSEventName', ename);
      this.dialog = false;
    },
    closeWin() {
      this.dialog = false;
    },
    addResource() {
      this.resAssgn.push({'ra': null, icsp: null, ha: null});
    },
    formatDate(date) {
      if (!date) return '';
      const d = new Date(date);
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const year = d.getFullYear();
      return `${month}/${day}/${year}`;
    },
    formatDateN(date) {
      if (!date) return '';
      const d = new Date(date);
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const year = d.getFullYear();
      return `${year}-${month}-${day}`;
    },
    onStartDateSelected(date) {
      this.showStartDatePicker = false;
      this.startDate = date;
    },
    onEndDateSelected(date) {
      this.showEndDatePicker = false;
      this.endDate = date;
    },
  },
  created() {
    this.name = this.userName; // Set the initial value from the store
  },
}
</script>
<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="70vw"
  >
    <template v-slot:activator="{ props }">
      <v-btn
          density="comfortable"
          v-bind="props"
          variant="outlined"
      >ICS-214
      </v-btn>
    </template>
    <v-card theme="light">
      <v-card-title class="text-left ma-3">
        Create Activity Log (ICS-214)
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col class="pa-0 pr-1">
            <v-autocomplete
                v-model="selectedEvent"
                density="compact"
                hide-details
                label="Event"
                variant="outlined"
                :items="eventList"
                item-title="l"
                item-value="v"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="pa-0 px-1">
            <v-text-field
                v-model="formattedStartDate"
                density="compact"
                hide-details
                label="From Date"
                persistent-hint
                readonly
                variant="outlined"
                @click="showStartDatePicker = !showStartDatePicker"
            ></v-text-field>
            <v-date-picker
                v-if="showStartDatePicker"
                v-model="startDate"
                :max="endDate"
                elevation="24"
                @update:model-value="onStartDateSelected"
            ></v-date-picker>
          </v-col>
          <v-col class="pa-0 pl-1">
            <v-text-field
                v-model="formattedEndDate"
                density="compact"
                hide-details
                label="To Date "
                persistent-hint
                readonly
                variant="outlined"
                @click="showEndDatePicker = !showEndDatePicker"
            ></v-text-field>
            <v-date-picker
                v-if="showEndDatePicker"
                v-model="endDate"
                :min="startDate"
                elevation="24"
                @update:model-value="onEndDateSelected"
            ></v-date-picker>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-0 pr-1 pt-2">
            <v-text-field
                v-model="name"
                density="compact"
                hide-details
                label="Name"
                persistent-hint
                variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 pt-2 px-1">
            <v-text-field
                v-model="startTime"
                density="compact"
                hide-details
                label="Time From (HHMM)"
                persistent-hint
                variant="outlined"
            >
            </v-text-field>
          </v-col>
          <v-col class="pa-0 pl-1 pt-2">
            <v-text-field
                v-model="endTime"
                density="compact"
                hide-details
                label="Time to (HHMM)"
                persistent-hint
                variant="outlined"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-0 pr-1 pt-2">
            <v-text-field
                v-model="agency"
                density="compact"
                hide-details
                label="Your agency"
                persistent-hint
                variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 pt-2 px-1">
            <v-text-field
                v-model="loggedas"
                density="compact"
                hide-details
                label="Logged in As"
                persistent-hint
                variant="outlined"
            >
            </v-text-field>
          </v-col>
          <v-col class="pa-0 pl-1 pt-2">
            <v-text-field
                v-model="position"
                density="compact"
                hide-details
                label="Your position"
                persistent-hint
                variant="outlined"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-0 pr-1 pt-2">
            <v-text-field
                v-model="reliefName"
                density="compact"
                hide-details
                label="Relief Name"
                persistent-hint
                variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 pt-2 px-1">
            <v-text-field
                v-model="reliefTime"
                density="compact"
                hide-details
                label="Relief Time"
                persistent-hint
                variant="outlined"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-for="rsrc in resAssgn" no-gutters>
          <v-col class="pa-0 pr-1 pt-2">
            <v-text-field
                v-model="rsrc['ra']"
                density="compact"
                hide-details
                label="Resource assigned"
                persistent-hint
                variant="outlined"
            >
            </v-text-field>
          </v-col>
          <v-col class="pa-0 pt-2 px-1">
            <v-text-field
                v-model="rsrc['ics']"
                density="compact"
                hide-details
                label="ICS Position"
                persistent-hint
                variant="outlined"
            >
            </v-text-field>
          </v-col>
          <v-col class="pa-0 pl-1 pt-2">
            <v-text-field
                v-model="rsrc['ha']"
                density="compact"
                hide-details
                label="Home agency"
                persistent-hint
                variant="outlined"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-0 pr-1 pt-2">
            <v-btn
                prepend-icon="mdi-plus"
                variant="text"
                @click="addResource"
            >
              add resource
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
            variant="outlined"
            @click="closeWin"
        >
          close
        </v-btn>
        <v-btn
            variant="outlined"
            @click="saveICS"
            :disabled="!selectedEvent"
        >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
</style>