<script>
import { signInWithEmailAndPassword } from 'firebase/auth';
import iicepStore from "@/iicepStore.js";
import {authApp} from "@/main.js";
import {apiClientForm, getUserData, initializeDataLoad, objectToFormData} from "@/services.js";

export default {
  methods: {
    async tryLogIn () {
      try {
        localStorage.setItem('logged', '0');
        const response = await signInWithEmailAndPassword(authApp, this.email, this.password);
        localStorage.setItem('umail', this.email);
        const resp = await getUserData();
        const data = resp.data;
        if (data && data.ulist && data.ulist.length>0) {
          this.munilist=[];
          this.muniposlist={};
          this.userobj = {} ;
          if (data.ulist.length>0) {
            this.userobj.MEMBER_ID = data.ulist[0].ID ;
            this.userobj.FIRST_NAME = data.ulist[0].FirstName ;
            this.userobj.LAST_NAME = data.ulist[0].LastName ;
            this.userobj.WORK = data.ulist[0].Work ;
            this.userobj.CELL = data.ulist[0].Cell ;
            this.userobj.email = this.email ;
          }
          data.ulist.forEach(uel => {
            if (this.inMuniList(uel)) {
              this.muniposlist[uel.MuniCode.toString()].push({rv:uel.Role,rn:uel.Label});
            } else {
              let kkey = uel.MuniCode.toString();
              this.muninames[kkey] = uel.MuniName;
              this.munilist.push({code:kkey, name:uel.MuniName});
              this.muniposlist[kkey]= [{rv:uel.Role,rn:uel.Label}];
            }
          });
          this.currentMuni=this.munilist[0].code;
          this.currentRole=this.muniposlist[this.currentMuni][0].rv;
          this.showStep2=true;
          console.log("logged -1");
        }
        console.log("logged -1a");
      } catch ({}) {
        console.error('Login failed:')
      }
    },
    realLogin: async function () {
      let self = this;
      let rlabel = '';
      let retval = false;
      let udata ;
      this.muniposlist[this.currentMuni].forEach(rlpair => {
        if (rlpair.rv === this.currentRole) {
          rlabel = rlpair.rn;
        }
      });
      if ('' !== rlabel) {
        udata = {
          'MEMBER_ID' : this.userobj.MEMBER_ID,
          'MEMBER_MAIL' : this.userobj.email,
          'ROLE': this.currentRole,
          'ROLELAB': rlabel,
          'MCODE': this.currentMuni,
          'MNAME': this.muninames[this.currentMuni],
          'WORK' : this.userobj.WORK,
          'CELL' : this.userobj.CELL,
          'FIRST_NAME':this.userobj.FIRST_NAME,
          'LAST_NAME': this.userobj.LAST_NAME,
          'MODE': this.currentVersion
        };
        try {
          const response = await apiClientForm.post('register.php', objectToFormData(udata))
          this.dialog=false;
          if (response.data.status === "OK") {
            iicepStore.data.currentVersionLabel = iicepStore.data.versionLabels[this.currentVersion];
            this.userobj['MODE']=this.currentVersion;
            this.userobj['MuniCode']=this.currentMuni;
            this.userobj['MuniName']=this.muninames[this.currentMuni];
            this.userobj['CountyCode']=response.data['CCODE'];
            this.userobj['CountyName']=response.data['CNAME'];
            self.$store.commit('setLoggedUserObj', this.userobj);
            localStorage.setItem('ICSEventID', null);
            localStorage.setItem('ICSEventName', null);
            localStorage.setItem("logged","1");
            localStorage.setItem("currentVersion", this.currentVersion);
            localStorage.setItem("currentMuniCode", this.currentMuni);
            localStorage.setItem("currentMuniName", this.muninames[this.currentMuni]);
            localStorage.setItem("currentCountyCode", response.data['CCODE']);
            localStorage.setItem("currentCountyName", response.data['CNAME']);
            self.$store.commit('setVersionLabel', $versionLabels[this.currentVersion]);
            initializeDataLoad();
            const intendedDestination = self.$store.getters.getIntendedDestination?self.$store.getters.getIntendedDestination:'/' ;
            if (intendedDestination) {
              self.$store.commit('clearIntendedDestination');
              await self.$router.push(intendedDestination);
            }
            self.dialog = false;
            retval = true;
          } else {
            self.$store.commit('setLoggedUserObj', null);
          }
        } catch ({}) {
          self.$store.commit('setLoggedUserObj', null);
        }
      }
      return retval;
    },
    setRole() {
      this.currentRole=this.muniposlist[this.currentMuni][0].rv;
    },
    recoverPassword() {

    },
    inMuniList: function (obj) {
      let inval = false;
      this.munilist.forEach(mel => {
        if (obj['MuniName'] === mel.name && obj.MuniCode.toString() === mel.code) {
          inval = true;
        }
      })
      return inval;
    }
  },
  computed: {
    dlgwid() {
      if (this.$vuetify.display.name==='xs') {
        return '90vw';
      } else if (this.$vuetify.display.name==='sm') {
        return '70vw';
      } else if (this.$vuetify.display.name==='md') {
        return '40vw';
      } else if (this.$vuetify.display.name==='lg') {
        return '30vw';
      } else {
        return '25vw';
      }
    }
  },
  data: () => ({
    email: '',
    password: '',
    dialog: false,
    visible: false,
    munilist:[],
    muniposlist: [],
    muninames: {},
    userobj: {},
    currentMuni: null,
    currentRole: null,
    currentVersion: '',
    showStep2:false,
    iicepVersions: [{v:'IICEP ACTIVE', k:'ACTIVE'},{v:'IICEP Practice&Exercise', k:'DEMO'}],
  }),
  created() {
    this.dialog=!this.$store.getters.isLoggedUser;
  },
  mounted() {
  }
}
</script>
<template>
  <v-row >
    <v-dialog
        v-model="dialog"
        persistent
        :width="dlgwid"
    >
      <v-card>
        <v-card-title class="text-center mt-3"> Log In </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col
                  class="pa-0 px-2"
              >
                <v-text-field
                    density="compact"
                    placeholder="Email address"
                    prepend-inner-icon="mdi-email-outline"
                    variant="outlined"
                    v-model="email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0 px-2"
              >
                <v-text-field
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="visible ? 'text' : 'password'"
                    density="compact"
                    placeholder="Enter your password"
                    prepend-inner-icon="mdi-lock-outline"
                    variant="outlined"
                    v-model="password"
                    @click:append-inner="visible = !visible"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                    variant="outlined"
                    density="comfortable"
                    v-model="currentVersion"
                    :items="iicepVersions"
                    item-title="v"
                    item-value="k"
                    label="Active OR Practice&Exercise"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg=7 class="d-flex justify-space-evenly align-center">
                <v-btn
                    class="mx-1"
                    variant="outlined"
                    @click="dialog = false"
                >Cancel
                </v-btn>
                <v-btn
                    class="mx-1"
                    variant="outlined"
                    @click="tryLogIn"
                > Log In
                </v-btn>
              </v-col>
              <v-col cols="12" lg=5 class="d-flex justify-center align-center">
                <v-btn
                    class="mx-1 "
                    variant="text"
                    density="compact"
                    @click="recoverPassword"
                > Lost password
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                    v-show="showStep2"
                    variant="outlined"
                    density="comfortable"
                    v-model="currentMuni"
                    :items="munilist"
                    item-title="name"
                    item-value="code"
                    label="Jurisdiction"
                    @update:modelValue="setRole"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                    v-show="showStep2"
                    variant="outlined"
                    density="comfortable"
                    v-model="currentRole"
                    :items="muniposlist[currentMuni]"
                    item-title="rn"
                    item-value="rv"
                    label="Role"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              :v-show="showStep2"
              @click="realLogin"
          >
            PROCEED TO IICEP
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<style scoped>
</style>