import polyline from "@mapbox/polyline";
import {getCoords} from "@turf/turf";
import {circle} from "@turf/circle";
import {polygon} from "@turf/helpers";
import {rhumbDestination} from "@turf/rhumb-destination";

export const annoColors = {
    '#000000': {
        color: '#000000',
        ln: {strokeColor: '#111111', strokeOpacity: 1, strokeWeight: 3},
        pg: {fillColor: '#000000', fillOpacity: 0.7, strokeColor: '#111111', strokeOpacity: 1, strokeWeight: 1}
    },
    '#ff8000': {
        color: '#ff8000',
        ln: {strokeColor: '#ff8000', strokeOpacity: 1, strokeWeight: 3},
        pg: {fillColor: '#ff8000', fillOpacity: 0.7, strokeColor: '#e67300', strokeOpacity: 1, strokeWeight: 1}
    },
    '#ffff00': {
        color: '#ffff00',
        ln: {strokeColor: '#ffff00', strokeOpacity: 1, strokeWeight: 3},
        pg: {fillColor: '#ffff00', fillOpacity: 0.7, strokeColor: '#e6e600', strokeOpacity: 1, strokeWeight: 1}
    },
    '#009933': {
        color: '#009933',
        ln: {strokeColor: '#009933', strokeOpacity: 1, strokeWeight: 3},
        pg: {fillColor: '#009933', fillOpacity: 0.7, strokeColor: '#006622', strokeOpacity: 1, strokeWeight: 1}
    },
    '#cccccc': {
        color: '#cccccc',
        ln: {strokeColor: '#cccccc', strokeOpacity: 1, strokeWeight: 3},
        pg: {fillColor: '#cccccc', fillOpacity: 0.7, strokeColor: '#666666', strokeOpacity: 1, strokeWeight: 1}
    },
    '#003366': {
        color: '#003366',
        ln: {strokeColor: '#003366', strokeOpacity: 1, strokeWeight: 3},
        pg: {fillColor: '#003366', fillOpacity: 0.7, strokeColor: '#0026d4', strokeOpacity: 1, strokeWeight: 1}
    },
    '#6699cc': {
        color: '#6699cc',
        ln: {strokeColor: '#6699cc', strokeOpacity: 1, strokeWeight: 3},
        pg: {fillColor: '#6699cc', fillOpacity: 0.7, strokeColor: '#3973ac', strokeOpacity: 1, strokeWeight: 1}
    },
    '#ffff99': {
        color: '#ffff99',
        ln: {strokeColor: '#ffff99', strokeOpacity: 1, strokeWeight: 3},
        pg: {fillColor: '#ffff99', fillOpacity: 0.7, strokeColor: '#ffff00', strokeOpacity: 1, strokeWeight: 1}
    },
    '#ff0000': {
        color: '#ff0000',
        ln: {strokeColor: '#ff0000', strokeOpacity: 1, strokeWeight: 3},
        pg: {fillColor: '#ff0000', fillOpacity: 0.7, strokeColor: '#cc0000', strokeOpacity: 1, strokeWeight: 1}
    }
};

export const incIcons = [
    {
        "ID": "101",
        "Name": "Abandoned Vehicle - Private Property",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "101",
        "Symbol": "101.png"
    },
    {
        "ID": "102",
        "Name": "Autumn Leaf Collection",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "112",
        "Symbol": "102.png"
    },
    {
        "ID": "104",
        "Name": "Down Wires - Private Property",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "104",
        "Symbol": "104.png"
    },
    {
        "ID": "105",
        "Name": "Electric Interruption - Private Property",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "105",
        "Symbol": "105.png"
    },
    {
        "ID": "106",
        "Name": "Fallen Tree - Private Property",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "106",
        "Symbol": "106.png"
    },
    {
        "ID": "107",
        "Name": "Flooding\/High Water - Private Property",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "107",
        "Symbol": "107.png"
    },
    {
        "ID": "108",
        "Name": "Illegal Dumping",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "109",
        "Symbol": "108.png"
    },
    {
        "ID": "109",
        "Name": "Recycling Collection",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "110",
        "Symbol": "109.png"
    },
    {
        "ID": "110",
        "Name": "Trash Collection",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "111",
        "Symbol": "110.png"
    },
    {
        "ID": "111",
        "Name": "Grafitti",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "108",
        "Symbol": "111.png"
    },
    {
        "ID": "112",
        "Name": "Bulk Trash Collection",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "112",
        "Symbol": "112.png"
    },
    {
        "ID": "113",
        "Name": "Utility Outage\/Problem - Private property",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "113",
        "Symbol": "113.png"
    },
    {
        "ID": "114",
        "Name": "Other (Township Services)",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "114",
        "Symbol": "114.png"
    },
    {
        "ID": "115",
        "Name": "Bagged Leaves\/Yard Debris",
        "IGroup": "1",
        "IType": "Township Services",
        "DisplayOrder": "115",
        "Symbol": "115.png"
    },
    {
        "ID": "201",
        "Name": "Dead Animal",
        "IGroup": "2",
        "IType": "Public Health Instances",
        "DisplayOrder": "201",
        "Symbol": "201.png"
    },
    {
        "ID": "202",
        "Name": "Rodent Control",
        "IGroup": "2",
        "IType": "Public Health Instances",
        "DisplayOrder": "202",
        "Symbol": "202.png"
    },
    {
        "ID": "203",
        "Name": "Sanitation Enforcement",
        "IGroup": "2",
        "IType": "Public Health Instances",
        "DisplayOrder": "203",
        "Symbol": "203.png"
    },
    {
        "ID": "204",
        "Name": "Other (Public Health)",
        "IGroup": "2",
        "IType": "Public Health Instances",
        "DisplayOrder": "204",
        "Symbol": "204.png"
    },
    {
        "ID": "301",
        "Name": "Abandoned Vehicle \/ Public Property ",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "301",
        "Symbol": "301.png"
    },
    {
        "ID": "302",
        "Name": "Down Wires",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "302",
        "Symbol": "302.png"
    },
    {
        "ID": "303",
        "Name": "Dangerous Road Conditions",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "303",
        "Symbol": "303.png"
    },
    {
        "ID": "304",
        "Name": "Debris in Roadway",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "304",
        "Symbol": "304.png"
    },
    {
        "ID": "305",
        "Name": "Electric Interruption",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "305",
        "Symbol": "305.png"
    },
    {
        "ID": "306",
        "Name": "Fallen Tree In Roadway",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "306",
        "Symbol": "306.png"
    },
    {
        "ID": "307",
        "Name": "Flooding\/High Water",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "307",
        "Symbol": "307.png"
    },
    {
        "ID": "308",
        "Name": "Hazardous Condition\/Spill",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "308",
        "Symbol": "308.png"
    },
    {
        "ID": "309",
        "Name": "Pothole(s) in Roadway",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "309",
        "Symbol": "309.png"
    },
    {
        "ID": "310",
        "Name": "Railroad Crossing - Defective Lights",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "310",
        "Symbol": "310.png"
    },
    {
        "ID": "311",
        "Name": "Road or Intersection Closure",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "311",
        "Symbol": "311.png"
    },
    {
        "ID": "312",
        "Name": "Signage Repair\/Maint.",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "312",
        "Symbol": "312.png"
    },
    {
        "ID": "313",
        "Name": "Snow \/Ice Removal",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "313",
        "Symbol": "313.png"
    },
    {
        "ID": "314",
        "Name": "Street &amp; Road Repair\/Maint.",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "314",
        "Symbol": "314.png"
    },
    {
        "ID": "315",
        "Name": "Structural Condition - Bridge\/Overpass",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "316",
        "Symbol": "315.png"
    },
    {
        "ID": "316",
        "Name": "Traffic Light Outage",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "317",
        "Symbol": "316.png"
    },
    {
        "ID": "317",
        "Name": "Utility Outage\/Problem-Public property",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "318",
        "Symbol": "317.png"
    },
    {
        "ID": "318",
        "Name": "OTHER (Public Safety)",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "320",
        "Symbol": "318.png"
    },
    {
        "ID": "319",
        "Name": "Street Light Outage\/Problem",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "319",
        "Symbol": "319.png"
    },
    {
        "ID": "320",
        "Name": "Suspicious Activity",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "320",
        "Symbol": "320.png"
    },
    {
        "ID": "321",
        "Name": "Accident-Vehicle",
        "IGroup": "3",
        "IType": "Public Safety Instances",
        "DisplayOrder": "321",
        "Symbol": "321.png"
    }
]
export const annoIcons = [
    // {value: "436", icon:"436.png", label: "Accident - expect delays"},
    {value: "436", icon: "436.png", label: "Accident"},
    {value: "435", icon: "435.png", label: "Community event"},
    {value: "434", icon: "434.png", label: "Community center"},
    {value: "433", icon: "433.png", label: "Municipal facility"},
    {value: "432", icon: "432.png", label: "Voting Location"},
    {value: "431", icon: "431.png", label: "Detour in effect"},
    // {value: "430", icon:"430.png", label: "Road Repair - Expect Delays"},
    // {value: "429", icon:"429.png", label: "Construction Zone - Expect Delays"},
    {value: "430", icon: "430.png", label: "Road Repair"},
    {value: "429", icon: "429.png", label: "Construction Zone"},
    {value: "428", icon: "428.png", label: "Recycling Collection Site"},
    {value: "427", icon: "427.png", label: "Debris Collection Site"},
    {value: "426", icon: "426.png", label: "Parks and Open Space"},
    {value: "425", icon: "425.png", label: "Recreational Facility/Field"},
    {value: "424", icon: "424.png", label: "Emergency Operations Center"},
    {value: "423", icon: "423.png", label: "Base Camp"},
    {value: "422", icon: "422.png", label: "Incident Command Center"},
    {value: "421", icon: "421.png", label: "Staging Area"},
    {value: "420", icon: "420.png", label: "Fire Station"},
    {value: "419", icon: "419.png", label: "Fire-EMS Station"},
    {value: "418", icon: "418.png", label: "Forest Fire"},
    {value: "417", icon: "417.png", label: "Major Fire - Seek Detour"},
    {value: "416", icon: "416.png", label: "Flooded Area-Large Area"},
    {value: "415", icon: "415.png", label: "Hospital"},
    {value: "414", icon: "414.png", label: "Medical Facility"},
    {value: "412", icon: "412.png", label: "Police Station"},
    {value: "411", icon: "411.png", label: "Reunification Site"},
    {value: "410", icon: "410.png", label: "School"},
    {value: "409", icon: "409.png", label: "Shelter"},
    {value: "408", icon: "408.png", label: "EMS Station"},
    {value: "407", icon: "407.png", label: "Flooded Area"},
    {value: "406", icon: "406.png", label: "Helispot"},
    {value: "405", icon: "405.png", label: "Helibase"},
    {value: "404", icon: "404.png", label: "Camp"},
    {value: "403", icon: "403.png", label: "Boil Water Advisory"},
    {value: "402", icon: "402.png", label: "Briefing"},
    {value: "401", icon: "401.png", label: "Nuclear Power Plant"}
];

export const annoColorNames = [
    {value: 0, label: 'Road Closure', clr: '#000000', cl: 'bg-black'},
    {value: 1, label: 'Detour', clr: '#ff8000', cl: 'bg-amber-darken-3'},
    {value: 2, label: 'Road Repair/Maintenance', clr: '#ffff00', cl: 'bg-yellow-accent-2'},
    {value: 3, label: 'Evacuation Route', clr: '#009933', cl: 'bg-green-accent-3'},
    {value: 4, label: 'Boil Water Advisory', clr: '#cccccc', cl: 'bg-grey-lighten-1'},
    {value: 5, label: 'Flooded Area', clr: '#003366', cl: 'bg-blue-darken-4'},
    {value: 6, label: 'Flooding/High Water', clr: '#6699cc', cl: 'bg-blue-lighten-2'},
    {value: 7, label: 'Show/Ice', clr: '#ffff99', cl: 'bg-yellow-lighten-2'},
    {value: 8, label: 'Forrest Fire', clr: '#ff0000', cl: 'bg-red-accent-3'}
];

export const geoJSONtemplate = {
    type: 'Feature',
    geometry: {
        type: '',
        coordinates: []
    },
    properties: {}
}

export function Atyp1ToGeoJ(obj) {
    const tempobj = JSON.parse(JSON.stringify(geoJSONtemplate));
    Object.keys(obj).forEach(k => {
        if (k !== 'geom' && k !== 'coords') {
            tempobj.properties[k] = obj[k];
        }
    });
    tempobj.geometry.type = 'Polygon';
    tempobj.geometry = polyline.toGeoJSON(obj['geom']);
    tempobj.geometry.coordinates = [tempobj.geometry.coordinates];
    return tempobj;
}

export function Atyp2ToGeoJ(obj) {
    const tempobj = JSON.parse(JSON.stringify(geoJSONtemplate));
    Object.keys(obj).forEach(k => {
        if (k !== 'geom' && k !== 'coords') {
            tempobj.properties[k] = obj[k];
        }
    });
    tempobj.geometry = polyline.toGeoJSON(obj['geom']);
    return tempobj;
}

export function Atyp5ToGeoJ(obj) {
    const tempobj = JSON.parse(JSON.stringify(geoJSONtemplate));
    Object.keys(obj).forEach(k => {
        if (k !== 'geom' && k !== 'coords') {
            tempobj.properties[k] = obj[k];
        }
    });
    tempobj.properties.icon = 'im' + tempobj.properties.icon.replace('.png', '');
    tempobj.geometry.type = 'Point';
    let geomM = obj.geom.split(',').map((x) => parseFloat(x))
    tempobj.geometry.coordinates = [geomM[1], geomM[0]];
    return tempobj;
}


export function AObjToGeoJSON(obj) {
    let tempobj;
    switch (obj.typ) {
        case "1":
            tempobj = Atyp1ToGeoJ(obj);
            break;
        case "2":
            tempobj = Atyp2ToGeoJ(obj);
            break;
        case "3":
            // tempobj.geometry.type = 'Polygon';
            // tempobj.geometry.coords = [polyline.toGeoJSON(obj['geom'])];
            tempobj = null;
            break;
        case "4":
            // tempobj.geometry.type = 'Polygon';
            // let geomC = obj.geom.split(',').map((x)=>parseFloat(x))
            // tempobj.geometry.coords = [geomC[1],geomC[0]];
            tempobj = null;
            break;
        case "5":
            tempobj = Atyp5ToGeoJ(obj);
            break;
        default:
            tempobj = null;
            break;
    }
    return tempobj;
}

const isopleth_consts = {
    radio: {},
    hazmat: {
        iso1: [
            [1500.0, 400.0],
            [7900.0, 3000.0]
        ],
        iso2: [60, 500]
    },
    explosives: {
        expdist: [
            [21, 365],
            [34, 518],
            [46, 563],
            [98, 579],
            [122, 731],
            [195, 1158],
            [269, 1554],
            [475, 2834]
        ],
        expsize: ['5 lbs.', '20 lbs.', '50 lbs.', '500 lbs.', '1,000 lbs.', '4,000 lbs.', '10,000 lbs.', '50,000 lbs.'],
        expdesc: ['Pipe Bomb', 'Suicide Bomber', 'Briefcase/Suitcase', 'Car', 'SUV/Van', 'Small Delivery Truck', 'Container/Water Truck', 'Semi-Trailer'],
        feetdist: [
            [70, 1200],
            [110, 1700],
            [150, 1850],
            [320, 1900],
            [400, 2400],
            [640, 3800],
            [860, 5100],
            [1570, 9300]
        ]
    }
}

function exppoly1(cen, ssize) {
    const props = {
        id: 1,
        fill_clr: '#ff0000',
        fill_op: 0.30,
        str_clr: '#ff0000',
        str_op: 0.4,
        str_wid: 2
    }
    const tcircle = circle(cen, isopleth_consts.explosives.expdist[ssize][0],
        {steps: 24, units: "meters", properties: props}
    )
    return tcircle;
}

function exppoly2(cen, ssize) {
    const props = {
        id: 2,
        fill_clr: '#ff8080',
        fill_op: 0.30,
        str_clr: '#ff8080',
        str_op: 0.3,
        str_wid: 2
    }
    const circlepoly = circle(
        cen,
        isopleth_consts.explosives.expdist[ssize][0],
        {steps: 24, units: "meters", properties: props}
    )
    return (circlepoly);
}

function exppoly3(cen, ssize) {
    const props = {
        id: 3,
        fill_clr: '#ffff00',
        fill_op: 0.20,
        str_clr: '#ffff00',
        str_op: 0.2,
        str_wid: 2
    }
    const isocircle = circle(
        cen,
        isopleth_consts.explosives.expdist[ssize][0],
        {steps: 24, units: "meters", properties: props}
    )
    return isocircle;
}

function hazpoly1(cen, ssize) {
    const props = {
        id: 1,
        fill_clr: '#ff0000',
        fill_op: 0.35,
        str_clr: '#ff0000',
        str_op: 1,
        str_wid: 2
    };
    const hcircle = circle(
        cen,
        isopleth_consts.hazmat.iso2[ssize],
        {steps: 24, units: "meters", properties: props}
    )
    return hcircle;
}

function hazpoly2(cen, wdir, ssize, stime) {
    let wdir1 = wdir + 180;
    if (wdir1 > 360) wdir1 = wdir1 - 360;
    let a1 = wdir1 - 90;
    let a2 = wdir1 + 90;
    if (a1 > 360) a1 = a1 - 360;
    if (a2 > 360) a2 = a2 - 360;
    let pnts = [];
    const destination = rhumbDestination(cen, isopleth_consts.hazmat.iso2[ssize], a2, {units: "meters"});
    pnts.push(getCoords(destination));
    let a0 = wdir1 + 30;
    for (let ii = 0; ii < 13; ii++) {
        a0 = a0 - 5;
        if (a0 < 0) a0 = a0 + 360;
        if (a0 > 360) a0 = a0 - 360;
        pnts.push(getCoords(rhumbDestination(cen, isopleth_consts.hazmat.iso1[ssize][stime], a0, {units: "meters"})));
    }
    pnts.push(getCoords(rhumbDestination(cen, isopleth_consts.hazmat.iso2[ssize], a1, {units: "meters"})));
    const tmp = polygon(pnts, {
        id: 2,
        fill_clr: '#ffff00',
        fill_op: 0.3,
        str_clr: '#ffff00',
        str_op: 1,
        str_wid: 2
    });
    return (tmp);
}

function radpoly1(cen, wdir1) {
    let wdir = wdir1 + 180;
    if (wdir > 360) wdir = wdir - 360;
    let p1 = [];
    p1.push(cen);
    p1.push(getCoords(rhumbDestination(cen, 10 * 1609, wdir - 33.75, {units: "meters"})));
    p1.push(getCoords(rhumbDestination(cen, 10 * 1609, wdir - 11.25, {units: "meters"})));
    let tmp = polygon(p1, {
        id: 1,
        fill_clr: '#ffff00',
        fill_op: 0.3,
        str_clr: '#ffff00',
        str_op: 1,
        str_wid: 2
    });
    return (tmp);
}

function radpoly2(cen, wdir1) {
    let wdir = wdir1 + 180;
    if (wdir > 360) wdir = wdir - 360;
    let p1 = [];
    p1.push(cen);
    p1.push(getCoords(rhumbDestination(cen, 10 * 1609, wdir + 33.75, {units: "meters"})));
    p1.push(getCoords(rhumbDestination(cen, 10 * 1609, wdir + 11.25, {units: "meters"})));
    let tmp = polygon(p1, {
        id: 2,
        fill_clr: '#ffff00',
        fill_op: 0.3,
        str_clr: '#ffff00',
        str_op: 1,
        str_wid: 2
    });
    return (tmp);
}

function radpoly3(cen, wdir1) {
    let wdir = wdir1 + 180;
    if (wdir > 360) wdir = wdir - 360;
    let p1 = [];
    p1.push(cen);
    p1.push(getCoords(rhumbDestination(cen, 10 * 1609, wdir - 11.25, {units: "meters"})));
    p1.push(getCoords(rhumbDestination(cen, 10 * 1609, wdir + 11.25, {units: "meters"})));
    let tmp = polygon(p1, {
        id: 3,
        fill_clr: '#ff0000',
        fill_op: 0.3,
        str_clr: '#ff0000',
        str_op: 1,
        str_wid: 2
    });
    return (tmp);
}

function radpoly4(cen) {
    let props = {
        id: 4,
        fill_clr: '#ff0000',
        fill_op: 0.30,
        str_clr: '#ff0000',
        str_op: 1,
        str_wid: 2
    };
    const isocircle = circle(
        cen,
        805,
        {steps: 24, units: "meters", properties: props}
    )
    return (isocircle);
}

function radpoly5(cen) {
    let props = {
        id: 5,
        fill_clr: '#ffffff',
        fill_op: 0.10,
        str_clr: '#ffffff',
        str_op: 0.7,
        str_wid: 2
    };
    const isocircle = circle(
        cen,
        1609,
        {steps: 24, units: "meters", properties: props}
    )
    return (isocircle);
}

function radpoly6(cen) {
    let props = {
        id: 6,
        fill_clr: '#ffffff',
        fill_op: 0.10,
        str_clr: '#ffffff',
        str_op: 0.7,
        str_wid: 2
    };
    const isocircle = circle(
        cen,
        8045,
        {steps: 24, units: "meters", properties: props}
    )
    return (isocircle);
}

function radpoly7(cen) {
    let props = {
        id: 7,
        fill_clr: '#ffffff',
        fill_op: 0.10,
        str_clr: '#ffffff',
        str_op: 0.7,
        str_wid: 2
    };
    const isocircle = circle(
        cen,
        16090,
        {steps: 36, units: "meters", properties: props}
    )
    return (isocircle);
}

function radpoly8(cen) {
    let props = {
        id: 8,
        fill_clr: '#c0c0c0',
        fill_op: 0.15,
        str_clr: '#c0c0c0',
        str_op: 1,
        str_wid: 2
    };
    const isocircle = circle(
        cen,
        80450,
        {steps: 48, units: "meters", properties: props}
    )
    return (isocircle);
}

function radpoly9(cen, wdir1) {
    let wdir = wdir1 + 180;
    if (wdir > 360) wdir = wdir - 360;
    let p1 = [];
    p1.push(cen);
    p1.push(getCoords(rhumbDestination(cen, 50 * 1609, wdir - 33.75, {units: "meters"})));
    p1.push(getCoords(rhumbDestination(cen, 50 * 1609, wdir - 11.25, {units: "meters"})));
    let tmp = polygon(p1, {
        id: 9,
        fill_clr: '#ffff00',
        fill_op: 0.15,
        str_clr: '#ffff00',
        str_op: 1,
        str_wid: 2
    });
    return (tmp);
}

function radpoly10(cen, wdir1) {
    let wdir = wdir1 + 180;
    if (wdir > 360) wdir = wdir - 360;
    let p1 = [];
    p1.push(cen);
    p1.push(getCoords(rhumbDestination(cen, 50 * 1609, wdir + 33.75, {units: "meters"})));
    p1.push(getCoords(rhumbDestination(cen, 50 * 1609, wdir + 11.25, {units: "meters"})));
    const tmp = polygon(p1, {
        id: 10,
        fill_clr: '#ffff00',
        fill_op: 0.15,
        str_clr: '#ffff00',
        str_op: 1,
        str_wid: 2
    });
    return tmp;
}

function radpoly11(cen, wdir1) {
    let wdir = wdir1 + 180;
    if (wdir > 360) wdir = wdir - 360;
    let p1 = [];
    p1.push(cen);
    p1.push(getCoords(rhumbDestination(cen, 50 * 1609, wdir - 11.25, {units: "meters"})));
    p1.push(getCoords(rhumbDestination(cen, 50 * 1609, wdir + 11.25, {units: "meters"})));
    const tmp = polygon(p1, {
        id: 11,
        fill_clr: '#ff0000',
        fill_op: 0.15,
        str_clr: '#ff0000',
        str_op: 1,
        str_wid: 2
    });
    return tmp;
}

export function radiso(cen , wdir) {
    const radfc = {
        'type': 'FeatureCollection',
        'features': []
    };
    radfc.features.push(radpoly1(cen, wdir));
    radfc.features.push(radpoly2(cen, wdir));
    radfc.features.push(radpoly3(cen, wdir));
    radfc.features.push(radpoly4(cen, wdir));
    radfc.features.push(radpoly5(cen, wdir));
    radfc.features.push(radpoly6(cen, wdir));
    radfc.features.push(radpoly7(cen, wdir));
    radfc.features.push(radpoly8(cen, wdir));
    radfc.features.push(radpoly9(cen, wdir));
    radfc.features.push(radpoly10(cen, wdir));
    radfc.features.push(radpoly11(cen, wdir));
    return radfc;
}

export function haziso(cen, wdir, ssize, stime) {
    const hazfc = {
        'type': 'FeatureCollection',
        'features': []
    };
    hazfc.features.push(hazpoly1(cen, ssize));
    hazfc.features.push(hazpoly2(cen, wdir, ssize, stime));
    return hazfc;
}

export function expiso(cen, ssize) {
    const expfc = {
        'type': 'FeatureCollection',
        'features': []
    };
    expfc.features.push(exppoly1(cen, ssize));
    expfc.features.push(exppoly2(cen, ssize));
    expfc.features.push(exppoly3(cen, ssize));
    return expfc;
}