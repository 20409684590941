<script>
import {mapMutations, mapGetters} from "vuex";
import {geocodeAddress} from "@/services.js";

export default {
  methods: {
    ...mapMutations(['setTempCoords']),
    ...mapGetters(['getTempCoords']),
    goBack: function () {
      let self = this;
      self.$router.push({name: "resreqs"})
    },
    goBackAndSave: function() {
      let saveObj = {
        "eventid": this.rsEvents,
        "sitdesc": this.rsBrief,
        "restype": this.rsType,
        "resdesc": this.rsNeedDescr,
        "severity": this.rsSev,
        "fromdate": this.rsFrom,
        "todate": this.rsTo,
        "addinfo": this.rsAddNeed,
        "pcontact": this.rsPoC,
        "pphone": this.rsPocPhone,
        "pemail": this.rsPocMail,
        "paddress": this.rsAddr,
        "pstate": this.rsState,
        "pzip": this.rsZIP,
        "otherneed": this.rsNeed,
        "RRLat":  this.getTempCoords()[1],
        "RRLng":  this.getTempCoords()[0],
        "visib":this.rsVisibility
      }
      console.log(JSON.stringify(saveObj));
      // addResReq($_SESSION['MCODE'],$_SESSION['MEMBER_ID'],$args['eventid'],$args['sitdesc'],
      // $args['restype'],
      //     $args['resdesc'],$args['severity'],$args['fromdate'],$args['todate'],
      //     $args['addinfo'],$args['pcontact'],
      //     $args['pphone'],$args['pemail'],$args['paddress'],$args['pstate'],$args['pzip'],
      //     $args['otherneed'],$args['RRLat'],
      //     $args['RRLng'],$args['visib']);
    },
    selectionChanged: function () {
      console.log(JSON.stringify(this.selectedMunis))
    },
    searchAddress() {
      let addr = this.rsAddr ;
      if (this.rsState && this.rsState!=='') {
        addr = addr + ',' + this.rsState;
      }
      if (this.rsZIP && this.rsZIP!=='') {
        addr = addr + ',' + this.rsZIP;
      }
      geocodeAddress(addr)
          .then(
              data => {
                let location = [];
                if (data['Status'] === 'OK') {
                  location = data.location;
                }
                this.setTempCoords(location);
              })
          .catch(
              error => {
                console.error(error)
              }
          );
    }
  },
  data: () => ({
    rsName: null,
    rsCounty: null,
    rsPhone: null,
    rsEmail: null,
    rsType: null,
    rsSev:null,
    rsBrief:null,
    rsNeedDescr: null,
    rsFrom: null,
    rsTo:null,
    rsNeed:null,
    rsAddNeed: null,
    rsVisibility: null,
    rsEvents: null,
    rsPoC: null,
    rsPocPhone: null,
    rsPocMail: null,
    rsAddr: null,
    rsState: null,
    rsZIP: null,
    rsCoord: null,
    selectedMunis: [],
    // aggCodes: {
    //   "-34033":[882131,882135,885206,882064,882065,882133,882136,885348,882134,882132,1729723,882130,885385,1723212,885452]
    //   "99999":[]
    // },,99999,34001,34011,34015,882131,882135,885206,882064,882065,882133,882136,885348,882134,882132,1729723,882130,885385,1723212,885452
    // muniList: [{name:'Alloway Township',id:1, 'Carneys Point Township', 'Elmer Borough', 'Elsinboro Township', 'Lower Alloways Creek Township', 'Mannington Township', 'Oldmans Township', 'Penns Grove Borough', 'Pennsville Township', 'Pilesgrove Township', 'Pittsgrove Township', 'Quinton Township', 'Salem City', 'Upper Pittsgrove Township', 'WoodsTown Borough'],
    needLabels: ['Operators Needed', 'Skills Needed', 'Supplies Needed', 'Other Needs'],
  }),
  mounted() {
    let userObj = this.$store.state.loggedUserObj();
    this.rsName = userObj.FIRST_NAME + ' ' + userObj.LAST_NAME;
    this.rsPhone = userObj.CELL;
    this.rsCounty = userObj.MuniName;
    this.rsEmail = userObj.email;
  },
  computed: {
    ...mapGetters(['getEvents','getRecipientsA']),
    muniList() {
      return this.getRecipientsA.map(rec => ({'l': rec.Name, 'v': rec.Code}));
    },
    eventItems() {
      return this.getEvents.map(ev => ({'l': ev.ename, 'v': ev.id, 'a': false}));
    },
    needLabel() {
      let nt = this.$rrNeedTypes.find(nt => nt.v===this.rsType);
      return nt?nt.l:'Resource';
    },
    concatenatedSelectedItems() {
      return this.selectedMunis
          .map((v) => this.muniList.find((item) => item.v === v).l)
          .join(', ');
    },
  }
}
</script>

<template>
  <v-card>
    <v-card-title class="text-center">
      <span class="text-h5">Create New Resource Request</span>
    </v-card-title>
    <v-card-text>
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col
              class="pb-2 pr-2"
              cols="6"
          >
            <v-text-field
                density="compact"
                label="Request submitted by"
                required
                variant="outlined"
                persistent-hint
                hide-details="true"
                v-model="rsName"
            ></v-text-field>
          </v-col>
          <v-col
              class="pb-2 pl-2"
              cols="6"
          >
            <v-text-field
                density="compact"
                label="County"
                variant="outlined"
                persistent-hint
                hide-details="true"
                v-model="rsCounty"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
              class="pb-2 pr-2"
              cols="6"
          >
            <v-text-field
                density="compact"
                label="Phone number"
                required
                variant="outlined"
                hide-details="true"
                v-model="rsPhone"
            ></v-text-field>
          </v-col>
          <v-col
              class="pb-2 pl-2"
              cols="6"
          >
            <v-text-field
                density="compact"
                label="e-mail address"
                required
                variant="outlined"
                hide-details="true"
                v-model="rsEmail"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pr-2">
            <span class="text-body-2 font-weight-bold">Resource need</span>
          </v-col>
          <v-col class="pl-2">
            <span class="text-body-2 font-weight-bold">Situation brief</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
              cols="6"
              class="pr-2"
          >
            <v-row no-gutters>
              <v-col cols="12" class="pb-2">
                <v-autocomplete
                    :items="$rrNeedTypes"
                    item-title="l"
                    item-value="v"
                    density="compact"
                    label="Select resource needed"
                    variant="outlined"
                    hide-details
                    v-model="rsType"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pb-2">
                <v-autocomplete
                    :items="$rrSeverity"
                    item-value="v"
                    item-title="l"
                    density="compact"
                    label="Select need severity"
                    variant="outlined"
                    hide-details
                    v-model="rsSev"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    clearable
                    density="compact"
                    label="Describe Resource need here"
                    required
                    rows="2"
                    variant="outlined"
                    hide-details
                    no-resize
                    v-model="rsNeedDescr"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col
              cols="6"
              class="pl-2"
          >
            <v-row no-gutters>
              <v-col cols="12">
                <v-textarea
                    no-resize
                    density="compact"
                    label="Enter situation brief here..."
                    required
                    rows="6"
                    variant="outlined"
                    hide-details
                    v-model="rsBrief"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pr-2">
            <span class="text-body-2 font-weight-bold">{{needLabel}}</span>
          </v-col>
          <v-col class="pl-2">
            <span class="text-body-2 font-weight-bold"></span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
              class="pb-2 pr-2"
              cols="6"
          >
            <v-text-field
                density="compact"
                label="From"
                required
                variant="outlined"
                persistent-hint
                hide-details
                v-model="rsFrom"
            ></v-text-field>
          </v-col>
          <v-col
              class="pb-2 pl-2"
              cols="6"
          >
            <v-text-field
                density="compact"
                label="To"
                variant="outlined"
                persistent-hint
                hide-details
                v-model="rsTo"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
              class="pr-2"
              cols="6"
          >
            <v-text-field
                clearable
                density="compact"
                :label="needLabels[rsType]"
                persistent-hint
                variant="outlined"
                hide-details
                v-model="rsNeed"
            ></v-text-field>
          </v-col>
          <v-col
              class="pl-2"
              cols="6"
          >
            <v-text-field
                clearable
                density="compact"
                label="Additional needs"
                variant="outlined"
                hide-details
                persistent-hint
                v-model="rsAddNeed"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pr-2">
            <span class="text-body-2 font-weight-bold">Visibility</span>
          </v-col>
          <v-col class="pl-2">
            <span class="text-body-2 font-weight-bold">Related events</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
              class="pa-0"
              cols="6"
          >
            <v-autocomplete
                :items="muniList"
                item-title="l"
                item-value="v"
                class="mr-2"
                density="compact"
                label="Select jurisdiction"
                multiple
                variant="outlined"
                hide-details
                v-model="selectedMunis"
                @update:model-value="selectionChanged"
            >
              <template v-slot:selection="{ item, index }">
                <div v-if="index===0" class="custom-selection">
                  {{ concatenatedSelectedItems }}
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
              class="pa-0"
              cols="6"
          >
            <v-autocomplete
                :items="eventItems"
                item-value="v"
                item-title="l"
                class="ml-2"
                density="compact"
                label="Select Related events"
                variant="outlined"
                hide-details
                v-model="rsEvents"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pr-2">
            <span class="text-body-2 font-weight-bold">Logistics</span>
          </v-col>
          <v-col class="pl-2">
            <span class="text-body-2 font-weight-bold"></span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
              class="pb-2 pr-2"
              cols="6"
          >
            <v-text-field
                density="compact"
                label="Point of contact"
                persistent-hint
                hide-details
                variant="outlined"
                v-model="rsPoC"
            >
            </v-text-field>
          </v-col>
          <v-col
              class="pb-2 pl-2"
              cols="2"
          >
            <v-text-field
                density="compact"
                label="Phone"
                variant="outlined"
                hide-details
                persistent-hint
                v-model="rsPocPhone"
            >
            </v-text-field>
          </v-col>
          <v-col
              class="pb-2 pl-2"
              cols="4"
          >
            <v-text-field
                density="compact"
                label="E-mail"
                required
                variant="outlined"
                persistent-hint
                hide-details
                v-model="rsPocMail"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
              class="pb-2 pr-2"
              cols="6"
          >
            <v-text-field
                density="compact"
                label="Address"
                variant="outlined"
                hide-details
                persistent-hint
                v-model="rsAddr"
            >
            </v-text-field>
          </v-col>
          <v-col
              class="pb-2 pl-2"
              cols="3"
          >
            <v-text-field
                density="compact"
                label="State"
                variant="outlined"
                hide-details
                persistent-hint
                v-model="rsState"
            ></v-text-field>
          </v-col>
          <v-col
              class="pb-2 pl-2"
              cols="2"
          >
            <v-text-field
                density="compact"
                label="ZIP"
                persistent-hint
                hide-details
                variant="outlined"
                v-model="rsZIP"
            ></v-text-field>
          </v-col>
          <v-col
              class="pb-2 pl-2"
              cols="1"
          >
            <v-btn
                class="mt-1"
                variant="outlined"
                icon="mdi-magnify"
                density="comfortable"
                @click="searchAddress"
            >
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-col class="pl-2 pr-2">
          <v-btn
              size="default"
              variant="outlined"
              width="100%"
              @click="goBack"
          >
            Close
          </v-btn>
        </v-col>
        <v-col class="pl-2 pr-2">
          <v-btn
              size="default"
              variant="outlined"
              width="100%"
              @click="goBackAndSave"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<style scoped>
::v-deep(.v-field__input) {
  --v-field-padding-start: 8px;
  --v-field-padding-end: 8px;
}
.custom-selection {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>