<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card
            color="#385F73"
            theme="dark"
        >
          <v-card-title class="text-h5">
            <v-row>
              <v-col cols="11">
                IICEP&nbsp;<span class="text-red">{{  this.$store.state._main_versionLabel }}</span>&nbsp;Dashboard
              </v-col>
              <v-col cols="1">
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn
                        color="blue-grey-lighten-4"
                        icon="mdi-account"
                        theme="light"
                        v-bind="props"
                    >
                    </v-btn>
                  </template>
                  <v-list theme="light">
                    <v-list-item @click="showHelp">
                      <v-list-item-title>Help</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="reqSupport">
                      <v-list-item-title>Request Tech Support</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="changePass">
                      <v-list-item-title>Change Password</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logOut">
                      <v-list-item-title text-color="blue-grey-lighten-4">Log Out</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="custom-scrollbar" cols="12" lg="4" md="6" xl="4">
                <v-card
                    color="blue-grey-lighten-4"
                    height="28vh">
                  <v-card-subtitle>
                    <div class="d-flex justify-space-between">
                      <div class="font-weight-bold">
                        Documents
                      </div>
                      <div>
                        <add-new-db-document/>
                      </div>
                    </div>
                  </v-card-subtitle>
                  <v-card-text class="pa-0 pt-1">
                    <v-table
                        class="bg-blue-grey-lighten-3"
                        density="compact"
                        fixed-header
                        height="25vh">
                      <tbody>
                      <tr
                          v-for="item in dbdocs"
                          :key="item['ID']"
                      >
                        <td>
                          <a :href="homeUrl+'getDbFile.php?id='+item['ID']" class='text-black'>
                            {{ item['Caption'] }}
                          </a>
                        </td>
                        <td>{{ item['Owner'] }}</td>
                        <td>
                          <v-btn
                              theme="light"
                              variant="flat"
                              icon = "mdi-delete-outline"
                              size="small"
                              density="compact"
                              @click="confirmDeleteDbDoc(item['ID'],item['Caption'])"
                          >
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </v-table>
                    <ConfirmDialogue ref="confirmDbDocDialog" />
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="custom-scrollbar" cols="12" lg="4" md="6" xl="4">
                <v-card
                    color="blue-grey-lighten-4"
                    height="28vh">
                  <v-card-subtitle>
                    <div class="d-flex justify-space-between">
                    <div class="font-weight-bold">
                      Links
                    </div>
                    <div>
                      <add-new-db-link/>
                    </div>
                    </div>
                  </v-card-subtitle>
                  <v-card-text class="pa-0 pt-1 ">
                    <v-table
                        class="bg-blue-grey-lighten-3"
                        color="blue-grey-lighten-4"
                        density="compact"
                        fixed-header
                        height="25vh">
                      <tbody>
                      <tr
                          v-for="item in dblinks"
                          :key="item['ID']"
                      >
                        <td><a :href="item['Url']" class="text-blue-grey-darken-4"
                               target="_blank">{{ item['Caption'] }}</a></td>
                        <td>
                          <v-btn
                              theme="light"
                              variant="flat"
                              icon = "mdi-delete-outline"
                              size="small"
                              density="compact"
                              @click="confirmDeleteDbLink(item['ID'],item['Caption'])"
                          >
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </v-table>
                    <ConfirmDialogue ref="confirmDbLinkDialog" />
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                        class="ma-1 "
                        size="small"
                        variant="outlined"
                    >new link
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="custom-scrollbar" cols="12" lg="4" md="6" xl="4">
                <v-card
                    color="blue-grey-lighten-4"
                    height="28vh">
                  <v-card-subtitle class="font-weight-bold">Priority Messages</v-card-subtitle>
                  <v-card-text class="pa-0 pt-1">
                    <v-table
                        class="bg-blue-grey-lighten-3"
                        density="compact"
                        fixed-header
                        height="25vh">
                      <thead>
                      <tr>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Message Time
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Message Type
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Message
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="msg in messages">
                        <td>{{ msg['msgtime'] }}</td>
                        <td>
                          {{ msg.in === 1 ? 'IN' : 'OUT' }}
                          {{ msg.priority ? 'Priority' : '' }}
                          {{ msg.immediate ? 'Immediate' : '' }}
                        </td>
                        <td>{{ msg['msgtext'] }}</td>
                      </tr>
                      </tbody>
                    </v-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="custom-scrollbar" cols="12" lg="4" md="6" xl="4">
                <v-card
                    color="blue-grey-lighten-4"
                    height="28vh">
                  <v-card-subtitle class="font-weight-bold">Active Events</v-card-subtitle>
                  <v-card-text class="pa-0 pt-1 ">
                    <v-table
                        id="testtable1"
                        class="bg-blue-grey-lighten-3"
                        color="blue-grey-lighten-4"
                        density="compact"
                        height="25vh">
                      <thead>
                      <tr>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Event Name
                        </th>
                        <th class=" font-weight-regular text-center bg-blue-grey-lighten-1">
                          Type
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          EOC Status
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Prognosis
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Event status
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in events"
                          :key="item.id">
                        <td class="text-truncate text-decoration-underline">
                          <a @click="goToEdb(item.id,item.ename)" class="text-blue-grey-darken-4">{{ item.ename }}</a>
                        </td>
                        <td>{{ item.evtypename }}</td>
                        <td>{{ item.eocstattxt }}</td>
                        <td>{{ item.prognosistxt }}</td>
                        <td>{{ item.statustxt }}</td>
                      </tr>
                      </tbody>
                    </v-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="custom-scrollbar" cols="12" lg="4" md="6" xl="4">
                <v-card
                    color="blue-grey-lighten-4"
                    height="28vh">
                  <v-card-subtitle class="font-weight-bold">Active Incidents</v-card-subtitle>
                  <v-card-text class="pa-0 pt-1">
                    <v-table
                        class="bg-blue-grey-lighten-3"
                        density="compact"
                        height="25vh">
                      <thead>
                      <tr>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Incident
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Type
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Address
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in incidents"
                          :key="item.id">
                        <td class="text-decoration-underline">
                          <edit-incident mode="db" :eid="item.id" :iid="item['iid']"/>
                        </td>
                        <td class="text-truncate">{{ item['itypename'] }}</td>
                        <td class="text-truncate">{{ item['address'] }}</td>
                      </tr>
                      </tbody>
                    </v-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="custom-scrollbar" cols="12" lg="4" md="6" xl="4">
                <v-card
                    color="blue-grey-lighten-4"
                    height="28vh">
                  <v-card-subtitle class="font-weight-bold">Resource Requests</v-card-subtitle>
                  <v-card-text class="pa-0 pt-1">
                    <v-table
                        class="bg-blue-grey-lighten-3 d-block w-100"
                        density="compact"
                        height="25vh">
                      <thead>
                      <tr>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Municipality
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Severity
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Res. Type
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1">
                          Order No.
                        </th>
                        <th class="font-weight-regular text-center bg-blue-grey-lighten-1 w-25">
                          Needed Resource
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in inresreqs"
                          :key="item.id"
                      >
                        <td>{{ item['jname'] }}</td>
                        <td>{{ item['severitytext'] }}</td>
                        <td>{{ item['rneedtxt'] }}</td>
                        <td class="text-decoration-underline">
                          <resource-request-response mode="db" :eid="item.id" :idx="item.idx"/>
                        </td>
                        <td class="d-table-cell text-truncate">{{ item['rneedescr'] }}</td>
                      </tr>
                      </tbody>
                    </v-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card
            color="#385F73"
            theme="dark"
        >
          <v-card-title class="text-h5">
            IICEP OEM
          </v-card-title>
          <v-card-subtitle>Manage events and incidents</v-card-subtitle>
          <v-card-actions>
            <v-btn
                class="ms-2"
                size="small"
                variant="outlined"
                @click="goToOemMain()"
            >Map
            </v-btn>
            <dutylog-add/>
            <dutylog-report mode="btn"/>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            color="#385F73"
            theme="dark"
        >
          <v-card-title class="text-h5">
            IICEP Administration
          </v-card-title>
          <v-card-subtitle>Manage users and permissions</v-card-subtitle>
          <v-card-actions>
            <useradmin/>
            <user-activity-log/>
            <online-users/>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            color="#385F73"
            theme="dark"
        >
          <v-card-title class="text-h5">
            IICEP Document Repository
          </v-card-title>
          <v-card-subtitle>Documents</v-card-subtitle>
          <v-card-actions>
            <file-repository/>
            <add-new-document/>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card
            color="#00695C"
            theme="dark"
        >
          <v-card-title class="text-h5">
            Emergency Workers
          </v-card-title>
          <v-card-subtitle>Emergency Workers Administration</v-card-subtitle>
          <v-card-actions>
            <v-btn
                size="small"
                variant="outlined"
                @click="openDosimetry"
            >Dosimetry Workers
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            color="#00695C"
            theme="dark"
        >
          <v-card-title class="text-h5">
            IICEP 911 Dashboard
          </v-card-title>
          <v-card-subtitle>Manage 911 incidents and resources</v-card-subtitle>
          <v-card-actions>
            <v-btn
                class="ms-2"
                size="small"
                variant="outlined"
                @click="open911"
            >911
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            color="#00695C"
            theme="dark"
        >
          <v-card-title class="text-h5">
            IICEP Damage Reports
          </v-card-title>
          <v-card-subtitle>Report Damaged Properties</v-card-subtitle>
          <v-card-actions>
            <v-btn
                class="ms-2"
                size="small"
                variant="outlined"
                @click="openDR"
            >Reports
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            color="#00695C"
            theme="dark"
        >
          <v-card-title class="text-h5">
            IICEP for FD Inventory
          </v-card-title>
          <v-card-subtitle>Fire Department Resources</v-card-subtitle>
          <v-card-actions>
            <v-btn
                class="ms-2"
                size="small"
                variant="outlined"
                @click="openFDInv"
            >Inventory
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import activityLog from "@/components/activityLog.vue";
import UserActivityLog from "@/components/userActivityLog.vue";
import DutylogReport from "@/components/dutylogReport.vue";
import DutylogAdd from "@/components/dutylogAdd.vue";
import usermenu from "@/components/usermenu.vue"
import iicepStore from "@/iicepStore.js";
import eventdb from "@/components/eventdb.vue";
import {SignOutUser} from "@/auth.js";
import AddNewDbDocument from "@/components/addNewDbDocument.vue";
import AddNewDbLink from "@/components/addNewDbLink.vue";
import AddNewDocument from "@/components/addNewDocument.vue";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";
import useradmin from "@/components/useradmin.vue";
import fileRepository from "@/components/fileRepository.vue";
import {saveData} from "@/services.js";
import OnlineUsers from "@/components/onlineUsers.vue";
import EditIncident from "@/components/editIncident.vue";
import ResourceRequestResponse from "@/components/ResourceRequestResponse.vue";

export default {
  components: {
    EditIncident,
    OnlineUsers,
    useradmin, fileRepository, ResourceRequestResponse,
    AddNewDocument, AddNewDbLink, AddNewDbDocument, DutylogReport, DutylogAdd, UserActivityLog,
    ConfirmDialogue, activityLog, usermenu, eventdb},
  name: 'dashboard',
  data: () => ({}),
  computed: {
    homeUrl() {
      return this.$backendUrl;
    },
    inresreqs() {
      let irr = this.$store.getters.getIncomingRRs;
      let irrlist = [];
      irr.forEach(r => {
        irrlist.push(r);
      });
      console.log('computed IRR', irrlist.length);
      return irrlist;
    },
    dbdocs() {
      let dbd = this.$store.getters.getDocs;
      let ddlist = [];
      dbd.forEach(d => {
        ddlist.push(d);
      });
      console.log('computed DD', ddlist.length);
      return ddlist;
    },
    dblinks() {
      let dbl = this.$store.getters.getLinks;
      let dllist = [];
      dbl.forEach(d => {
        dllist.push(d);
      });
      console.log('computed LL', dllist.length);
      return dllist;
    },
    events() {
      let ev = this.$store.getters.getEvents;
      let evlist = [];
      ev.forEach(e => {
        evlist.push(e);
      });
      console.log('computed EV', evlist.length);
      return evlist;
    },
    incidents() {
      let inc = this.$store.getters.getIncidents;
      let inclist = [];
      inc.forEach(i => {
        if (i.status ===2) {
          inclist.push(i);
        }
      });
      console.log('computed IN', inclist.length);
      return inclist;
    },
    messages() {
      return this.$store.getters.getDbPriMessages;
    }
  },
  methods: {
    async confirmDeleteDbDoc(docid,docname) {
      try {
        const result = await this.$refs.confirmDbDocDialog.open({
          title: 'Confirm Document Deletion',
          message: `Dashboard Document <strong>${docname}</strong> will be deleted`
        });
        if (result) {
          const fd = new FormData();
          fd.append('mode', 'deldbdoc');
          fd.append('docid', docid);
          await saveData(fd);
        }
      } catch (err) {
      }
    },
    async confirmDeleteDbLink(linkid,linkname) {
      try {
        const result = await this.$refs.confirmDbLinkDialog.open({
          title: 'Confirm Link Deletion',
          message: `Dashboard Link <strong>${linkname}</strong> will be deleted`
        });
        if (result) {
          const fd = new FormData();
          fd.append('mode', 'deldblink');
          fd.append('linkid', linkid);
          await saveData(fd);
        }
      } catch (err) {
      }
    },
    changePass: function () {

    },
    reqSupport: function () {

    },
    showHelp: function () {

    },
    logOut: function () {
      let self = this;
      SignOutUser();
      localStorage.setItem('logged', '0');
      localStorage.setItem('ICSEventID', null);
      localStorage.setItem('ICSEventName', null);
      localStorage.setItem('currentVersion', null);
      localStorage.setItem("currentMuniCode", null);
      localStorage.setItem("currentMuniName", null);
      localStorage.setItem("currentCountyCode", null);
      localStorage.setItem("currentCountyName", null);
      self.$store.commit('deleteUserObj');
      self.$router.push('/login');
    },
    goToEdb: function (eid,einame) {
      this.$store.commit('setICSEvent', eid, einame) ;
      this.$router.push("/eventdb");
    },
    goToOemMain: function () {
      let self = this;
      self.$router.push("/oem")
    },
    openDosimetry: function () {
      window.open('https://dosimetry.iicep.com/', '_dosimetry');
    },
    openFDInv: function () {
      window.open('https://iicep.com/fd/fd.php', '_fdinv');
    },
    open911: function () {
      window.open('https://911.iicep.com/911.php', '_open911');
    },
    openDR: function () {
      window.open('https://damagereports.iicep.com', '_openDR');
    },
  },
  watch: {},
  mounted() {
  }
}
</script>
<style>
/*
@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}
*/
.custom-scrollbar {
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: black;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}
</style>
