import './assets/main.css'

import {createApp} from 'vue'
import {createRouter, createWebHashHistory} from "vue-router";
import store from './store';
import App from './App.vue'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import dashboard from "@/components/dashboard.vue"
import shelters from "@/components/shelters.vue"
import rrequests from "@/components/rrequests.vue"
import incidents from "@/components/incidents.vue"
import onlineUsers from "@/components/onlineUsers.vue"
import events from "@/components/events.vue"
import fileRepository from "@/components/fileRepository.vue"
import useradmin from "@/components/useradmin.vue"
import oem_main from "@/components/oem_main.vue"
import loginFormDialogue from "@/components/loginFormDialogue.vue";
import {initializeApp} from 'firebase/app';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import addNewIncidentDialog from "@/components/addNewIncidentDialog.vue";
import addNewShelter from "@/components/addNewShelter.vue";
import addNewResReq from "@/components/addNewResReq.vue";
import eventdb from "@/components/eventdb.vue";
import {apiClient, apiClientSimple, getUserData, initializeDataLoad} from "@/services.js";
import {checkAuthState} from "@/auth.js";
import iicepStore from "@/iicepStore.js";

if (typeof global === 'undefined') {
    window.global = window;
}

let app;
const firebaseConfig = {
    apiKey: "AIzaSyBtZVZPr0TNn0jvZ_Yfh9ZvQZaUCQm5VSc",
    authDomain: "iicep-e8fc5.firebaseapp.com",
    databaseURL: "https://iicep-e8fc5-default-rtdb.firebaseio.com",
    projectId: "iicep-e8fc5",
    storageBucket: "iicep-e8fc5.appspot.com",
    messagingSenderId: "935109385449",
    appId: "1:935109385449:web:ee4a82da7728c4ed46a478",
    measurementId: "G-P1G09RZ1BW"
};
const vl = {'ACTIVE':'', 'DEMO': 'Practice&Exercise'};

export const firebaseApp = initializeApp(firebaseConfig);
export const authApp = getAuth();

const vuetify = createVuetify({
    icons: {
        iconfont: 'mdi',
    }, components, directives,
});

const routes = [
   {path: '/', component: dashboard, name: 'home', meta: {requiresAuth: true, hdr: false, mapSidePanel: false}
}, {path: '/oem',
    component: oem_main,
    name: 'oemmain',
    meta: {requiresAuth: true, hdr: true, mapSidePanel: false},
    children: [
          {path: 'shelters', component: shelters, name: 'shelters', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'resource-requests', component: rrequests, name: 'resreqs', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'incidents', component: incidents, name: 'incidents', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'newincident', component: addNewIncidentDialog, name: 'newincident', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'newcenter',
            component: addNewShelter,
            name: 'newcenter',
            meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },{path: 'new-resource-request',
            component: addNewResReq,
            name: 'newresreq',
            meta: {requiresAuth: true, hdr: true, mapSidePanel: true}
        },
        {path: 'events', component: events, name: 'events', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}},
        {path: 'online', component: onlineUsers, name: 'onlineusers', meta: {requiresAuth: true, hdr: true, mapSidePanel: true}}
    ]
}, {path: '/login', component: loginFormDialogue, name: 'login_form', meta: {hdr: true, mapSidePanel: false}
}, {path: '/fileRepository',
    component: fileRepository,
    name: 'fileRepository',
    meta: {requiresAuth: true, hdr: true, mapSidePanel: false}
}, {path: '/eventdb',
    component: eventdb,
    name: 'eventdb',
    meta: {requiresAuth: true, hdr: true, mapSidePanel: false}
}, {path: '/administration', component: useradmin, name: 'useradmin', meta: {requiresAuth: true, hdr: true, mapSidePanel: false}
}];
const router = createRouter({
    history: createWebHashHistory(), routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = store.getters.isLoggedUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const loginPath = '/login';
    if (!!to.meta.mapSidePanel) {
        store.commit('setCollapsed', false);
    }
    if (requiresAuth && !isLoggedIn) {
        store.commit('setIntendedDestination', to.fullPath);
        next('/login'); // Redirect to login page if user is not logged in and route requires authentication
    } else {
        if ((from.path === loginPath || from.name === loginPath) && from.path === to.path) {
            store.commit('setIntendedDestination', '/');
            next();
        } else if ((to.path === loginPath || to.name === loginPath) && isLoggedIn) {
            next('/');
        } else {
            next();
        }
    }
})


checkAuthState().then(async (user,idToken) => {
    try {
        let versLabel = '';
        if (user) {
            localStorage.setItem('jwtToken', idToken);
            if (localStorage.getItem('logged') === '1' && !!localStorage.getItem('umail')) {
                const resp = await apiClientSimple.get('auth_.php', {params: {'email': localStorage.getItem('umail')}});
                if (resp.data.status ==='OK' && resp.data.data) {
                    let userObj = resp.data.data;
                    store.commit('setLoggedUserObj',userObj);
                    store.commit('setICSEvent',localStorage.getItem('ICSEventID'));
                    store.commit('setUserESF', userObj['Role']);
                    store.commit('setVersionLabel', vl[userObj['MODE']]);
                    initializeDataLoad();
                }
            }
        }
        console.log("start2");
        console.log(user);
        const app = createApp(App);
        app.config.globalProperties = {
            $interval: '',
            $user: {},
            $backendUrl: 'https://iicep.site/',
            $repoTypes: [{ l: 'SOP', v: 0 },
                         { l: 'EOP', v: 1 },
                         { l: 'IAP', v: 2 },
                         { l: 'Files', v: 3 },
                         { l: 'LE', v: 4 }],
            $yesno: [{'v':0,'l':'No'},
                     {'v':1,'l':'Yes'}],
            $rrNeedTypes: [
                {'v':0, 'l':'Equipment'},
                {'v':1, 'l':'Personnel'},
                {'v':2, 'l':'Supplies'},
                {'v':3, 'l':'Other need'}],
            $rrSeverity: [
                {'v':0, 'l':'Not Set'},
                {'v':1, 'l':'Low'},
                {'v':2, 'l':'Medium'},
                {'v':3, 'l':'High'}],
            $versionLabels: vl,
        };
        app.config.globalProperties.$user = user;
        app.use(store)
            .use(router)
            .use(vuetify)
            .mount('#app');
    } catch (error) {
        console.error('Failed to check auth state:', error);
    }
}).catch(error => {
    console.error('Failed to check auth state:', error);
});

