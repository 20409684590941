<script>
import newResourceRequest from "@/components/addNewResReq.vue";
import AddNewEventDialog from "@/components/addNewEventDialog.vue";
import EditEventDialog from "@/components/editEventDialog.vue";
import DutylogDialog from "@/components/dutylogReport.vue";
import SitrepDialog from "@/components/sitrepDialog.vue";
import AddNewEventStatus from "@/components/addNewEventStatus.vue";
import {mapGetters} from "vuex";

export default {
  name: 'events',
  value: 'Events',
  components: {AddNewEventStatus, SitrepDialog, DutylogDialog, EditEventDialog, AddNewEventDialog, newResourceRequest},
  props: {},
  data: () => ({
    layoutInline: true,
    eventStatus: 'Open',
  }),
  methods: {},
  watch: {},
  mounted() {
  },
  computed: {
    ...mapGetters(['getEvents']),
    eventList() {
      return this.getEvents
    }
  }
}
</script>
<template>
  <v-card
      variant="flat"
      color="white"
      min-width="500"
  >
    <v-card-title class="text-center"> Events </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="ma-4 mb-2">
          <add-new-event-dialog/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-table density="comfortable">
            <thead>
            <tr>
              <th class="text-left">
                Event name
              </th>
              <th class="text-left">
                Type
              </th>
              <th class="text-left">
                Status
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody id="tableBody">
            <tr v-for="ev in eventList">
              <td>{{ ev['ename'] }}</td>
              <td>{{ ev['evtypename'] }}</td>
              <td> {{ ev['statustxt'] }} </td>
              <td class="pa-0 text-right d-flex justify-space-between align-center">
                <v-btn
                    v-if="ev.status<4"
                    class="ma-1"
                    icon=""
                    density="compact"
                    variant="flat"
                    @click=""
                    title = "Edit"
                ><edit-event-dialog  :eid="ev.id"/>
                </v-btn>
                <v-btn
                    v-if="ev.status===0 || ev.status===1"
                    class="ma-1"
                    icon=""
                    density="compact"
                    variant="flat"
                    @click=""
                    title="Sitrep"
                > <sitrep-dialog/> </v-btn>
                <v-btn
                    v-if="ev.status===2 || ev.status===1"
                    class="ma-1"
                    icon="mdi-state-machine"
                    density="compact"
                    variant="flat"
                    @click=""
                    title = "Status"
                > <add-new-event-status/></v-btn>
                <v-btn
                    v-if="ev.status===0 || ev.status===1"
                    class="ma-1"
                    icon="mdi-alpha-e"
                    density="compact"
                    variant="flat"
                    title="E"
                > </v-btn>
                <v-btn
                    v-if="ev.status===0 || ev.status===1"
                    class="ma-1"
                    icon=""
                    density="compact"
                    variant="flat"
                    @click=""
                    title="Dutylog"
                > <dutylog-dialog mode="icon" :eid="ev.id"/> </v-btn>
                <!--
                 pre-planning status ima edit i sitrep
                 open status ima edit sitrep status E i dutylog
                 open for analysis ima edit status E i dutylog
                 closed status ima samo edit
                 archived status ima samo edit

                 edit - preplanning, open, open for analys is, closed, archived
                 sitrep - pre-planning
                 status - open, open for analysis,
                 E - open, open for analysis
                 dutylog - open, open for analysis
                 -->
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style>
#tableBody{
  height: fit-content;
}
</style>