import axios from "axios";
import {createStore} from 'vuex';
import polyline from "@mapbox/polyline";
import {AObjToGeoJSON, geoJSONtemplate} from "@/utils.js"
import {apiClientSimple} from "@/services.js"

const store = createStore({
    state: {
        collapsed: false,
        users: [],
        dbdocs: [],
        dbprimessages:[],
        events: [],
        dblinks: [],
        incidents: [],
        incident_types: [],
        event_types: [],
        event_statuses: [],
        recipientsA: [],
        recipientsB: [],
        incomingrrs: [],
        uToken: null,
        bToken: null,
        loggedUserObj: null,
        counter: 0,
        intendedDestination: null,
        tms:{},
        updatedUsers:false,
        updatedIncidents:false,
        updatedEvents:false,
        updatedCenters:false,
        updatedRR:false,
        updatedRepoDocs:false,
        updatedDbDocs:false,
        updatedDbLinks:false,
        updatedPubAnnos:false,
        updatedPriAnnos:false,
        repofiles:[],
        tempPinCoords: [],
        onlineusers:[],
        newcenter: {},
        centers: {
            shelters:[],
            decons:[],
            recepts:[]
        },
        ownresreqs: [],
        // rrNeedTypes: [
        //     {'v':0, 'l':'Equipment'},
        //     {'v':1, 'l':'Personnel'},
        //     {'v':2, 'l':'Supplies'},
        //     {'v':3, 'l':'Other need'},
        // ],
        // rrSeverity: [
        //     {'v':0, 'l':'Not Set'},
        //     {'v':1, 'l':'Low'},
        //     {'v':2, 'l':'Medium'},
        //     {'v':3, 'l':'High'},
        // ],
        // annotations
        own_point_ftrs: [],
        own_line_ftrs: [],
        own_polygon_ftrs: [],
        own_rect_ftrs: [],
        own_circle_ftrs: [],
        foreign_point_ftrs: [],
        foreign_line_ftrs: [],
        foreign_polygon_ftrs: [],
        foreign_rect_ftrs: [],
        foreign_circle_ftrs: [],
        pubannolist: [],
        pubannos: [],
        priannolist: [],

        annoList: [],
        annoFtrSrc: [],

        rr_ftrs: [],
        irr_ftrs: [],
        inc_ftrs: [],
        ev_ftrs: [],

        sc_ftrs: [],
        dc_ftrs: [],
        rc_ftrs: [],
        isopleth_ftrs: [],
        anno_edits: false,
        mapmode: 'sat_map',
        draw_mode: 'simple_select',
        _draw_anno_selected: false,
        _draw_anno_msg: '',
        _draw_anno_id: null,
        _draw_edit: false,
        _draw_save_edits: false,
        _draw_cancel_edits: false,
        _draw_delete_sel: false,
        _ICS214_eventId: null,
        _ICS214_eventName: '',
        _fbase_User: null,
        _edb_evDescription: {
            prognosis:'',
            impact:'',
            prognosisnum:0,
            impactnum:0,
        },
        _edb_evRevisionList: [
            {   revId:0,
                fid:0,
                icsfile:'',
                user:'',
                userid:0,
                sitreptime:'',
                email:'',
                icsmsg:'',
                icsin:'',
                icsout:'',
                icspriority:'',
                icsimmediate:''
            }
        ],
        _edb_incidents: [],
        _edb_resreqs: [],
        _edb_afs:[],
        _edb_annos:[],

        _main_versionLabel: '',
        _main_accessLevel: 0,
        _main_esf: 0,
    },
    getters: {
        getCollapsed: state => state.collapsed,
        getNewCenter: state => state.newcenter,
        getUsers: state => state.users,
        getDocs: state => state.dbdocs,
        getLinks: state => state.dblinks,
        getEvents: state => state.events,
        getIncomingRRs: state => state.incomingrrs,
        getIncidents: state => state.incidents,
        isLoggedUser: state => !!state.loggedUserObj,
        getLoggedUserObj: state => state.loggedUserObj,
        getBToken: state => state.bToken,
        getUToken: state => state.uToken,
        getCounter: state => state.counter,
        getPubAnnos: state => state.pubannolist,
        getPriAnnos: state => state.priannolist,
        isUsersUpdatePending: state => state.updatedUsers,
        isIncidentsUpdatePending: state => state.updatedIncidents,
        isEventsUpdatePending: state => state.updatedEvents,
        isCentersUpdatePending: state => state.updatedCenters,
        isRRUpdatePending: state => state.updatedRR,
        isRepoDocsUpdatePending: state => state.updatedRepoDocs,
        isDbDocsUpdatePending: state => state.updatedDbDocs,
        isDbLinksUpdatePending: state => state.updatedDbLinks,
        isPubAnnosUpdatePending: state => state.updatedPubAnnos,
        isPriAnnosUpdatePending: state => state.updatedPriAnnos,
        getIntendedDestination: state => state.intendedDestination,
        getRepoFiles: state => state.repofiles,
        getIncidentTypes: state => state.incident_types,
        getTempCoords: state => state.tempPinCoords,
        getEventStatues: state => state.event_statuses,
        getEventTypes: state => state.event_types,
        getRecipientsA: state => state.recipientsA,
        getRecipientsB: state => state.recipientsB,
        getDbPriMessages: state => state.dbprimessages,
        getShelters: state => state.centers.shelters,
        getDecons: state => state.centers.decons,
        getRecepts: state => state.centers.recepts,
        getCenters: state => state.centers,
        getOwnRRs: state => state.ownresreqs,
    },
    mutations: {
        setVersionLabel(state,label) {
             state._main_versionLabel=label;
        },
        setAccessLevel(state,level) {
            state._main_accessLevel = level ;
        },
        setUserESF(state, esfRole) {
            state._main_esf = esfRole;
        },
        setICSEvent(state,id,name){
            state._ICS214_eventId=id;
            state._ICS214_eventName=name;
        },
        setDrawFtr(state, ftrid) {
            state._draw_anno_id=ftrid;
        },
        setDrawFtrMsg(state, msg) {
            state._draw_anno_msg=msg;
        },
        setDrawSelected(state, isObjSel) {
            state._draw_anno_selected = isObjSel;
        },
        setDrawSave(state, smode) {
            state._draw_save_edits = smode ;
        },
        setEditMode(state, emode) {
            state._draw_edit = emode ;
        },
        // set_own_line_ftrs(state, ftrs) {
        //     state.own_line_ftrs = ftrs ;
        // },
        // set_own_polygon_ftrs(state, ftrs) {
        //     state.own_polygon_ftrs = ftrs ;
        // },
        // set_own_rect_ftrs(state, ftrs) {
        //     state.own_rect_ftrs = ftrs ;
        // },
        // set_own_point_ftrs(state, ftrs) {
        //     state.own_point_ftrs = ftrs ;
        // },
        // set_own_circle_ftrs(state, ftrs) {
        //     state.own_circle_ftrs = ftrs ;
        // },
        setAnnoEdits(state, astatus) {
            state.anno_edits = astatus ;
        },
        setDrawMode(state, dmode) {
            state.draw_mode = dmode;
        },
        setIso_ftrs(state,geojson) {
          state.isopleth_ftrs = geojson;
        },
        setMapMode(state, mmode) {
            state.mapmode = mmode ;
        },
        setCollapsed(state) {
          state.collapsed = false ;
        },
        invertCollapsed(state) {
            state.collapsed = !state.collapsed ;
        },
        addNewRR(state, rr) {
            state.ownresreqs.push(rr);
        },
        closeRR(state, rrid) {

        },
        setOwnRRs(state, rrlist) {
            if (JSON.stringify(state.ownresreqs)!==JSON.stringify(rrlist)) {
                state.ownresreqs = rrlist;
                let klist = ['idx', 'jname', 'rneedtxt', 'rneedescr', 'severitytext', 'from', 'to'];
                let new_rr_ftrs = [];
                rrlist.forEach(irr => {
                    let tempobj = JSON.parse(JSON.stringify(geoJSONtemplate));
                    tempobj.geometry.coordinates=[irr.Lng,irr.Lat];
                    tempobj.geometry.type='Point';
                    klist.forEach(k => {
                        tempobj.properties[k] = irr[k]
                    });
                    new_rr_ftrs.push(tempobj);
                });
                state.rr_ftrs = new_rr_ftrs;
            }
        },
        createNewCenter(state,ctype){
            state.newcenter= {
                type: ctype
            }
        },
        setShelters(state,shelters) {
            state.centers['shelters']=shelters;
        },
        setDecons(state,decons) {
            state.centers['decons']=decons;
        },
        setRecepts(state,recepts) {
            state.centers['recepts']= recepts;
        },
        setOnlineusers(state, ousers){
            state.onlineusers = ousers;
        },
        setDbPriMessages(state, msgs) {
            state.dbprimessages=msgs ;
        },
        setEventTypes(state, etypes) {
            state.event_types=etypes;
        },
        setEventStatuses(state, estat) {
            state.event_statuses=estat;
        },
        setRecipientsA(state, recA) {
            state.recipientsA=recA;
        },
        setRecipientsB(state, recB) {
            state.recipientsB=recB;
        },
        setTempCoords(state, pcoords) {
            state.tempPinCoords=pcoords
        },
        hideTempPin(state) {
            state.tempPinCoords=null;
        },
        setIncidentTypes(state, itypes) {
            state.incident_types = [];
            itypes.forEach(it => {
                state.incident_types.push({"l": it.l.replace('&amp;','&'),"v": it.v});
            })
        },
        setRepoFiles(state, filelist) {
            state.repofiles = filelist;
        },
        setUsers(state, users) {
            state.users = users;
        },
        setDocs(state, docs) {
            state.dbdocs = docs;
        },
        setLinks(state, links) {
            let dl = [];
            links.forEach(l => {
                dl.push(l[0]);
                dl.push(l[1]);
            })
            state.dblinks = dl;
        },
        setEvents(state, evlist) {
            const keylist = ['ename', 'evtypename', 'prognosistxt', 'impacttxt', 'statustxt', 'address', 'id']
            if (JSON.stringify(evlist)!==JSON.stringify(state.events)) {
                state.events = evlist;
                let new_ev_ftrs = [];
                evlist.forEach(inc => {
                    let tempobj = JSON.parse(JSON.stringify(geoJSONtemplate));
                    tempobj.geometry.coordinates=[inc.Lng,inc.Lat];
                    tempobj.geometry.type='Point';
                    keylist.forEach(k => {
                        tempobj.properties[k] = inc[k]
                    });
                    new_ev_ftrs.push(tempobj);
                });
                state.ev_ftrs = new_ev_ftrs;
            }
        },
        setIncidents(state, inclist) {
            if (JSON.stringify(inclist)!==JSON.stringify(state.incidents)) {
                state.incidents = inclist;
                let keylist = [ 'itypename', 'statustext', 'address', 'descript', 'iid', 'updated', 'township', 'itype'];
                let new_inc_ftrs = [];
                inclist.forEach(inc => {
                    let tempobj = JSON.parse(JSON.stringify(geoJSONtemplate));
                    tempobj.geometry.coordinates=[inc.lng,inc.lat];
                    tempobj.geometry.type='Point';
                    keylist.forEach(k => {
                        tempobj.properties[k] = inc[k]
                    });
                    tempobj.properties['submitted'] = inc['subname'] + '\nphone: ' + inc['subphone'] + '\nmail: ' + inc['submail'];
                    tempobj.properties['coords'] = inc['lat'].toFixed(6) + ',' + inc['lng'].toFixed(6);
                    tempobj.properties['icon'] = 'im' + inc['itype'].toString()
                    new_inc_ftrs.push(tempobj);
                });
                state.inc_ftrs = new_inc_ftrs;
            }
        },
        setIncidentRejected(state, id) {
            state.incidents.forEach(iel => {
                if (iel.id === id) {
                    iel.status = 0 ;
                    iel.statustext = 'Rejected';
                }
            })
        },
        setIncidentNew(state, id) {
            state.incidents.forEach(iel => {
                if (iel.id === id) {
                    iel.status = 1 ;
                    iel.statustext = 'New';
                }
            })
        },
        setIncidentOpen(state, id) {
            state.incidents.forEach(iel => {
                if (iel.id === id) {
                    iel.status = 2 ;
                    iel.statustext = 'Open/Accepted';
                }
            })
        },
        setIncidentForwarded(state, id) {
            state.incidents.forEach(iel => {
                if (iel.id === id) {
                    iel.status = 3 ;
                    iel.statustext = 'Forwarded';
                }
            })
        },
        setIncidentClosed(state, id) {
            state.incidents.forEach(iel => {
                if (iel.id === id) {
                    iel.status = 4 ;
                    iel.statustext = 'Closed';
                }
            })
        },
        setIncidentPurged(state, id) {
            state.incidents.forEach(iel => {
                if (iel.id === id) {
                    iel.status = 5 ;
                    iel.statustext = 'Purged';
                }
            })
        },
        setIncomingRRs(state, irrlist) {
            if (JSON.stringify(state.incomingrrs)!==JSON.stringify(irrlist)) {
                state.incomingrrs = irrlist;
            }
        },
        setLoggedUserObj(state, loggeduserdata) {
            console.log ("logged-2");
            state.loggedUserObj = loggeduserdata;
        },
        deleteUserObj(state) {
            state.loggedUserObj = null;
        },
        setUToken(state, utoken) {
            state.uToken = utoken;
        },
        setBToken(state, btoken) {
            state.bToken = btoken;
        },
        setAnnos(state, annolist) {
            console.log('mutation - setAnnos');
            // 1-polygon
            // 2-polyline
            // 3-rectangle
            // 4-circle
            // 5-marker
            let tempannolist = [];
            annolist.forEach(anno => {
              const tempobj = AObjToGeoJSON(anno);
              if (!!tempobj) {
                  tempannolist.push(tempobj)
              }
            });
            if (!state.anno_edits && JSON.stringify(state.own_polygon_ftrs)!==JSON.stringify(tempannolist[1])) {
                state.annoList = tempannolist;
                state.annoFtrSrc =  {
                    'type': 'FeatureCollection',
                    'features': JSON.parse(JSON.stringify(tempannolist))
                };
            }
        },
        updateAnnosAfterDraw(state, ftrsrc) {
            state.annoFtrSrc = ftrsrc ;
        },
        setIntendedDestination(state, dest) {
            state.intendedDestination = dest;
        },
        clearIntendedDestination(state) {
            state.intendedDestination = null;
        },
        usersUpdatePending(state) {
            state.updatedUsers = true;
        },
        incidentsUpdatePending(state) {
            state.updatedIncidents = true;
        },
        eventsUpdatePending(state) {
            state.updatedEvents = true;
        },
        centersUpdatePending(state) {
            state.updatedCenters = true;
        },
        rrUpdatePending(state) {
            state.updatedRR = true;
        },
        repoDocsUpdatePending(state) {
            state.updatedRepoDocs = true;
        },
        dbDocsUpdatePending(state) {
            state.updatedDbDocs = true;
        },
        dbLinksUpdatePending(state) {
            state.updatedDbLinks = true;
        },
        pubAnnosUpdatePending(state) {
            state.updatedPubAnnos = true;
        },
        priAnnosUpdatePending(state) {
            state.updatedPriAnnos = false;
        },
        usersUpdateFinished(state) {
            state.updatedUsers = false;
        },
        incidentsUpdateFinished(state) {
            state.updatedIncidents = false;
        },
        eventsUpdateFinished(state) {
            state.updatedEvents = false;
        },
        centersUpdateFinished(state) {
            state.updatedCenters = false;
        },
        rrUpdateFinished(state) {
            state.updatedRR = false;
        },
        repoDocsUpdateFinished(state) {
            state.updatedRepoDocs = false;
        },
        dbDocsUpdateFinished(state) {
            state.updatedDbDocs = false;
        },
        dbLinksUpdateFinished(state) {
            state.updatedDbLinks = false;
        },
        pubAnnosUpdateFinished(state) {
            state.updatedPubAnnos = false;
        },
        priAnnosUpdateFinished(state) {
            state.updatedPriAnnos = false;
        },
        setTMS(state, tms) {
            state.tms = tms;
        },
        updateIncident(state, incdata) {
            state.incidents.forEach( u => {
                if (u.id === incdata.id) {
                    Object.keys(incdata).forEach( k => {
                        if (k !== 'id') {
                            u[k] = incdata[k];
                        }
                    })
                }
            })
        },
        addUser(state, newuserdata) {
            state.users.push(newuserdata);
        },
        deleteUser(state, delid) {
            let xdel = parseInt(delid);
            state.users = state.users.filter(object => object.userid !== xdel);
        },
        set_edb_evDescription(state, evdata) {
            state._edb_evDescription = evdata;
        },
        set_edb_evRevisionList(state, evdata) {
            state._edb_evRevisionList = evdata;
        },
        set_edb_incidents (state, idata) {
            state._edb_incidents = idata;
        },
        set_edb_resreqs (state, rdata) {
            state._edb_resreqs = rdata;
        },
        set_edb_afs (state, afsdata) {
            state._edb_afs = afsdata;
        },
        set_edb_annos (state, adata) {
            state._edb_annos = adata;
        },
    },
    actions: {
        retrieveOnlineUsers({commit, state}) {
            apiClientSimple.get('./getData.php', {params: {'mode': 'online'}})
                .then(response => {
                    commit('setOnlineusers', response.data['users']['EOC']);
                })
                .catch(error => {
                    console.error('Token verification failed:', error);
                });
        },
        createIncident({commit,state,newinc}) {

        },
        pullRepoFiles({commit, state}) {
            apiClientSimple.get('./getData.php', {params: {'mode': 'getrepofiles'}})
                .then(response => {
                    commit('setRepoFiles', response.data);
                })
                .catch(error => {
                    console.error('Token verification failed:', error);
                });

        },
        fetchDefinitions({commit, state}) {
            apiClientSimple.get('./getData.php', {params: {'mode': 'definitions'}})
                .then(response =>{
                    console.log('FD-11');
                    commit('setIncidentTypes', response.data.incident_types);
                    commit('setEventTypes', response.data.event_types);
                    commit('setEventStatuses', response.data.event_statuses);
                    commit('setRecipientsA', response.data.recipientsA);
                    commit('setRecipientsB', response.data.recipientsB);
                })
                .catch(error => {
                    console.error('Call failed:', error);
                })
        },
        fetchData({commit, state}) {
            apiClientSimple.get('./getData.php', {params: {'mode': 'load'}})
                .then(response=>{
                    commit('setUsers', response.data.userlist);
                    commit('setDocs', response.data.dbdocs);
                    commit('setLinks', response.data.dblinks);
                    commit('setEvents', response.data.events);
                    commit('setIncomingRRs', response.data.inresreqs);
                    commit('setIncidents', response.data.incidents);
                    commit('setDbPriMessages', response.data.messages);
                    commit('setShelters', response.data.emcenters.shelters);
                    commit('setDecons', response.data.emcenters.decons);
                    commit('setRecepts', response.data.emcenters.recepts);
                    commit('setOwnRRs', response.data.resreqs);
                    commit('setAnnos', response.data.pubannos);
                    let new_rr_ftrs = [];
                    let new_irr_ftrs = [];
                    let new_iso_ftrs = [];
                    const tmsobj = {};
                    Object.keys(response.data.tms).forEach(k =>{
                        if (k !=='MCode' && k!=='dbcalendar') {
                            tmsobj[k] = response.data.tms[k] ;
                        }
                    })
                    commit('setTMS', tmsobj);
                })
                .catch(error=>{

                })
        },
        checkForUpdates({commit, state}) {
            const tkn = state.bToken;
            const formData = new FormData();
            formData.append("mode", "tms");
            formData.append("token", tkn )
            const headers = {
                'Content-Type': 'multipart/form-data',
            };
            axios.post(
                './getData.php',
                formData,
                {
                    headers: headers
                })
                .then(response => {
                    const tmsobj = {};
                    Object.keys(response.data.tms).forEach(k =>{
                        if (k !=='MCode' && k!=='dbcalendar') {
                            tmsobj[k] = response.data.tms[k] ;
                            if (state.tms[k] < tmsobj[k]) {
                                switch (k) {
                                    case 'users':
                                        commit('usersUpdatePending');
                                        break;
                                    case 'incidents':
                                        commit('incidentsUpdatePending');
                                        break;
                                    case 'events':
                                        commit('eventsUpdatePending');
                                        break;
                                    case 'centers':
                                        commit('centersUpdatePending');
                                        break;
                                    case 'resreqs':
                                        commit('rrUpdatePending');
                                        break;
                                    case 'repodocs':
                                        commit('repoDocsUpdatePending');
                                        break;
                                    case 'dbdocs':
                                        commit('dbDocsUpdatePending');
                                        break;
                                    case 'dblinks':
                                        commit('dbLinksUpdatePending');
                                        break;
                                    case 'pubannos':
                                        commit('pubAnnosUpdatePending');
                                        break;
                                    case 'priannos':
                                        commit('priAnnosUpdatePending');
                                        break;
                                    default: break;
                                }
                            }
                        }
                    })
                    commit('setTMS', tmsobj);
                })
                .catch(error => {
                    console.error('Token verification failed:', error);
                });
        }
    },
})

export default store; // Export the Vuex store instance

