<script>
import {mapGetters, mapMutations} from 'vuex';
export default {
  methods: {
    goToHome: function () {
      let self = this;
      self.$router.push({name: "home"})
    },
    setEditEventId() {
      this.events.forEach(ev => {
        if (ev.id === this.eid) {
          this.ename = ev.ename;
          this.address = ev.address;
          this.evtype = ev.evtype;
          this.evtypename = ev.evtypename;
          this.prognosis = ev.prognosis;
          this.prognosistxt = ev.prognosistxt;
          this.impact = ev.impact;
          this.impacttxt = ev.impacttxt;
          this.eocstat = ev.eocstat;
          this.eocstattxt = ev.eocstattxt;
          this.estatus = ev.status;
          this.statustxt = ev.statustxt;
          this.dr = ev.dr;
          this.drcode = ev.drcode;
        }
      })
    },

  },
  data: () => ({
    ename: null,
    address: null,
    evtype:null,
    evtypename:null,
    prognosis:null,
    prognosistxt:null,
    impact:null,
    impacttxt:null,
    eocstat:null,
    eocstattxt:null,
    estatus:null,
    statustxt:null,
    dr:null,
    drcode:null,
    dialog: false,
  }),
  props: {
    eid: Number
  },
  computed: {
    ...mapGetters(['getEvents','getEventTypes','getEventStatues']),
    events() {
      return this.getEvents;
    },
    event_types() {
      return this.getEventTypes;
    },
    event_statuses() {
      return this.getEventStatues;
    },
  }
}
</script>
<template>
  <v-row >
    <v-dialog
        v-model="dialog"
        persistent
        width="45vw"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            v-bind="props"
            icon="mdi-pencil"
            density="compact"
            variant="flat"
            @click="setEditEventId"
        ></v-btn>
        <v-spacer></v-spacer>
      </template>
      <v-card>
        <v-card-title class="text-center mt-3">Edit Event "{{ename}}"</v-card-title>
        <v-card-text>
          <v-container class="pa-1">
            <v-row>
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-text-field
                    variant="outlined"
                    label="Event name"
                    density="compact"
                    readonly
                    v-model="ename"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  class="pa-0"
              >
                <v-text-field
                    variant="outlined"
                    label="Event address"
                    append-inner-icon="mdi-magnify"
                    density="compact"
                    readonly
                    v-model="address"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0"
                  cols="12">
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="event_types"
                    item-title="l"
                    item-value="v"
                    label="Event type"
                    v-model="evtype"
                    readonly
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="event_statuses"
                    item-value="v"
                    item-title="l"
                    label="Event Status"
                    v-model="estatus"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0"
                  cols="12"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="['Salem county','All Municipalities','EPZ towns','Atlantic County','Cumberland County','Gloucester county','Alloway Township', 'Carneys Point Township']"
                    label="Affected Jurisdiction"
                    multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  class="pa-0"
                  cols="6"
              >
                <v-autocomplete
                    variant="outlined"
                    density="compact"
                    :items="$yesno"
                    item-title="l"
                    item-value="v"
                    label="Dosimetry workers"
                    v-model="dr"
                ></v-autocomplete>
              </v-col>
              <v-col
                  class="pa-0 pl-3"
                  cols="2">
                <v-text-field
                    v-if="dr===1"
                    variant="outlined"
                    label="Event code"
                    density="compact"
                    readonly
                    v-model="drcode"
                ></v-text-field>

              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  class="text-center pa-0">
                <v-btn
                    variant="outlined"
                    width="100%"
                    :href="'https://iicep.com/iapqsf.php?formid='+eid"
                    target="_blank"
                    rel="noopener noreferrer"
                >IAP Quick Start Form</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
              class="ma-3 ml-2 pa-0"
              variant="outlined"
              @click="dialog = false"
              width="30%"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-3 mr-2 pa-0"
              variant="outlined"
              @click="dialog=false"
              width="30%"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<style>
</style>