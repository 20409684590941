<script>
import ActivityLog from "@/components/activityLog.vue";

export  default {
  components: {ActivityLog},
  methods: {
  },
  data() {
    return {
      dialog: false,
      eventName:'TestEvent'
    }
  }
}
</script>
<template>
  <v-container>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          width="80vw"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              v-bind="props"
              variant="flat"
              icon="mdi-file-cabinet"
              density="compact"
          >
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-left ma-3">
            <span class="text-h5"> Event {{eventName}} SitRep Revision #1</span>
          </v-card-title>
          <v-card-text>

            <v-row>

              <v-col class="pa-1">

                <v-row>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-text-field
                        variant="outlined"
                        label="Event name"
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>

                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-autocomplete
                        variant="outlined"
                        density="compact"
                        :items="['Not Set','Major Assistance Required','Under Control','Resolved','Unknown', 'Closed']"
                        label="Resolution Status"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-text-field
                        variant="outlined"
                        label="Event Status"
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-autocomplete
                        variant="outlined"
                        density="compact"
                        :items="['Not Set','Major','Moderate','Minor','Unknown']"
                        label="Event Severity"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="mb-1">
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-autocomplete
                        variant="outlined"
                        density="compact"
                        :items="['Not Set','Major Assistance Required','Under Control','Resolved','Unknown', 'Closed']"
                        label="Related events"
                        multiple=""
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-text-field
                        variant="outlined"
                        label="SCOEM"
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      class="pa-1 pt-1 pb-2"
                      cols="12"
                  >
                    <v-text-field
                        variant="outlined"
                        label="Scrolling Message"
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="pb-0">
                  <v-col
                      class="pa-1 pt-0 pb-0 "
                      cols="6"
                  >
                    <v-text-field
                        variant="outlined"
                        label="Wind Direction "
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-0 mb-0 mt-0"
                      cols="6"
                  >
                    <v-autocomplete
                        variant="outlined"
                        density="compact"
                        :items="['Not Set','Notification of Unusual Event','Alert','Site Area Emergency','General Emergency']"
                        label="Classification"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="pt-0 pb-0">
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-text-field
                        variant="outlined"
                        label="Wind Speed"
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-text-field
                        variant="outlined"
                        label="Condition"
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col
                      class="mt-2 pa-1 pt-0  pb-0 text-center"
                      cols="4"
                  >
                    <span class="text-center font-weight-bold " >Spill type: </span>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="4"
                  >
                    <v-checkbox
                        hide-details
                        id="checkbox3"
                        density="compact"
                        label="Large Spill"
                        value="Large Spill"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="4"
                  >
                    <v-checkbox
                        hide-details
                        id="checkbox3"
                        density="compact"
                        label="Daytime"
                        value="Daytime"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      class="pa-1 pt-1 pb-2"
                      cols="12"
                  > <!-- Situation brief + button -->
                    <v-textarea
                        density="compact"
                        class="mt-0"
                        label="Situation brief"
                        variant="outlined"
                        rows = "1"
                        auto-grow
                        required
                    ></v-textarea>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-2 mt-0 mb-3"
                      cols="12"
                  >
                      <activity-log
                          button-label="Record message to your activity log"
                          button-density="default"
                          button-size="default"/>
                  </v-col>
                </v-row>

                <v-row class="pt-2 pb-0 mt-2">
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-text-field
                        variant="outlined"
                        label="EOC 24/7 Phone"
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-text-field
                        variant="outlined"
                        label="Phone extension"
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="pt-2">
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-autocomplete
                        density="compact"
                        :items="['Full', 'Partial']"
                        label="Activation status"
                        variant="outlined"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-text-field
                        variant="outlined"
                        label="Address of Command Post"
                        density="compact"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="pt-0">
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  >
                    <v-autocomplete
                        density="compact"
                        :items="['Yes', 'No']"
                        label="Emergency declaration"
                        variant="outlined"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                      class="pa-1 pt-0 pb-0"
                      cols="6"
                  ><v-file-input
                        prepend-icon=""
                        append-inner-icon="mdi-paperclip"
                        clearable
                        label="Upload file"
                        variant="outlined"
                        density="compact"
                    ></v-file-input>
                  </v-col>
                </v-row>

              </v-col>

              <v-col
                      class="pa-1 pt-0"
                      cols="6"
              >
                <v-row>
                  <v-col
                      class="d-flex flex-row pa-1 pt-0"
                      cols="6"
                  >
                    <v-btn
                        class=" ma-1 ml-5 mr-3 "
                        variant="outlined"
                        size="default"
                        density="comfortable"
                    >IAP Quick start form </v-btn>
                  </v-col>

                  <v-col
                      class="d-flex flex-row-reverse pa-1 pt-0"
                      cols="6"
                  >
                    <v-btn
                        class=" ma-1 ml-1 "
                        variant="outlined"
                        size="default"
                        density="comfortable"
                    >All Hazard SitRep</v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                      class="mt-2 pa-1 pt-0  pb-0 text-center"
                      cols="12"
                  >
                    <span class="text-left font-weight-bold" >Essential functions: </span>
                    <span class="text-left" >Check all Essential functions considered fully operational for this Event: </span>
                  </v-col>
                </v-row>


                <v-row class="ml-4 mr-0 mb-0">

                  <v-col
                      class="d-flex flex-wrap pa-1 pt-0 pb-0"
                      cols="12"
                  >
                    <v-checkbox
                        id="checkbox3"
                        density="compact"
                        label="COG"
                        value="COG"
                        hide-details
                    >
                    </v-checkbox>
                    <v-checkbox
                        hide-details
                        id="checkbox3"
                        density="compact"
                        label="Power"
                        value="Power"
                    >
                    </v-checkbox>

                    <v-checkbox
                        hide-details
                        id="checkbox3"
                        density="compact"
                        label="LE"
                        value="LE"
                    >
                    </v-checkbox>
                    <v-checkbox
                        hide-details
                        id="checkbox3"
                        density="compact"
                        label="Fuel"
                        value="Fuel"
                    >
                    </v-checkbox>
                    <v-checkbox
                           hide-details
                           id="checkbox3"
                           density="compact"
                           label="Fire"
                           value="Fire"
                    >
                    </v-checkbox>
                    <v-checkbox
                           hide-details
                           id="checkbox3"
                           density="compact"
                           label="Water"
                           value="Water"
                    >
                    </v-checkbox>
                    <v-checkbox
                          hide-details
                          id="checkbox3"
                          density="compact"
                          label="EMS"
                          value="EMS"
                    >
                    </v-checkbox>
                    <v-checkbox
                          hide-details
                          id="checkbox3"
                          density="compact"
                          label="Sewer"
                          value="Sewer"
                    >
                    </v-checkbox>
                    <v-checkbox
                            hide-details
                            id="checkbox3"
                            density="compact"
                            label="MED"
                            value="MED"
                    >
                    </v-checkbox>
                    <v-checkbox
                            hide-details
                            id="checkbox3"
                            density="compact"
                            label="PW"
                            value="PW"
                    >
                    </v-checkbox>

                    </v-col>

                    </v-row>

                    <v-row>
                      <v-col
                          class="pt-0 pb-0 ma-2 mb-2 "
                          cols="12"
                      >
                        <v-textarea
                            density="compact"
                            class="mt-0"
                            label="County/Municipal issues"
                            variant="outlined"
                            rows = "2"
                            auto-grow
                            required
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row  class="mt-2 mr-0">
                      <v-col
                          class="pt-0 pr-0 mt-0 mb-0 ma-2"
                          cols="6"
                      >
                        <v-textarea
                            density="compact"
                            class="mt-0 pr-0"
                            label="Public Transportation"
                            variant="outlined"
                            rows = "1"
                            auto-grow
                            required
                        ></v-textarea>
                      </v-col>
                      <v-col
                          class="pt-0 pr-0 mt-0 mr-0 ma-2"
                          cols="5"
                      >
                        <v-text-field
                            class="pl-0 ml-0 mr-0 pr-0"
                            variant="outlined"
                            label="Date"
                            density="compact"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row  class="mt-0">
                      <v-col
                          class="pt-0 pr-0 pb-0 ml-2"
                          cols="6"
                      >
                        <v-autocomplete
                            variant="outlined"
                            density="compact"
                            :items="['Voluntary','Mandatory']"
                            label="Evacuation Type"
                            multiple=""
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                          class="pt-0 pr-0 mt-0 mr-0 ma-2"
                          cols="5"
                      >
                        <v-text-field
                            variant="outlined"
                            label="Date"
                            density="compact"
                            required
                        ></v-text-field>
                      </v-col>

                    </v-row>


                    <v-row   class="mt-0">
                      <v-col
                          class="pt-0 pr-0 pb-0 ml-2 mt-0"
                          cols="6"
                      >
                        <v-autocomplete
                            variant="outlined"
                            density="compact"
                            :items="['Yes','No']"
                            label="Shelter-In-Place"
                            multiple=""
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                          class="pt-0 pb-0 pr-0 mt-0 mr-0 ma-2"
                          cols="5"
                      >
                        <v-text-field
                            variant="outlined"
                            label="Date"
                            density="compact"
                            required
                        ></v-text-field>
                      </v-col>

                    </v-row>

                    <v-row  class="mt-0">
                      <v-col
                          class="pt-0 pb-0 ma-2 mb-2"
                          cols="12"
                      >
                        <v-textarea
                            density="compact"
                            class="mt-0"
                            label="Situation brief"
                            variant="outlined"
                            rows = "1"
                            auto-grow
                            required
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <v-row   class="mt-3">
                      <v-col
                          class="pt-0 pb-0 ml-2"
                          cols="12"
                      >
                        <v-autocomplete
                            variant="outlined"
                            density="compact"
                            :items="['Yes','No']"
                            label="Open Shelters"
                            multiple=""
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row  class="mt-0">
                      <v-col
                          class="pt-0 pb-0 ml-2"
                          cols="12"
                      >
                        <v-autocomplete
                            variant="outlined"
                            density="compact"
                            :items="['Yes','No']"
                            label="Open Decon Centers"
                            multiple=""
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row   class="mt-0">
                      <v-col
                          class="pt-0 pb-0 ml-2"
                          cols="12"
                      >
                        <v-autocomplete
                            variant="outlined"
                            density="compact"
                            :items="['Yes','No']"
                            label="Open Reception Centers"
                            multiple=""
                        ></v-autocomplete>
                      </v-col>
                    </v-row>




                  </v-col>



                </v-row>


              </v-card-text>




              <v-card-actions>
                <v-btn
                    class="ma-1 ml-0"
                    variant="outlined"
                    @click="dialog = false"
                    size="default"
                >
                  Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-1 mr-0"
                    variant="outlined"
                    @click="dialog=false"
                    size="default"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </template>
    <style>
    </style>