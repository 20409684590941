<script >
export default {
  props: ['buttonLabel', 'buttonDensity', 'buttonSize'],
  methods: {
    goToHome: function () {
      let self = this;
      self.$router.push({name: "home"})
    }
  },
  data: () => ({
    return:  {  },
    dialog: false,
    tableData: [
      {
        rev: '21/03/19',
        date: '12/12/21',
        time: '12:32:44',
        userName: 'Theo',
        position: 'Position',
        inOrOut: 'In',
        fileName: 'File1.txt',
      },
      {
        rev: '21/03/19',
        date: '12/12/21',
        time: '12:32:44',
        userName: 'Theo',
        position: 'Position',
        inOrOut: 'In',
        fileName: 'File1.txt',
      },
      {
        rev: '21/03/19',
        date: '12/12/21',
        time: '12:32:44',
        userName: 'Theo',
        position: 'Position',
        inOrOut: 'In',
        fileName: 'File1.txt',
      },
      {
        rev: '21/03/19',
        date: '12/12/21',
        time: '12:32:44',
        userName: 'Theo',
        position: 'Position',
        inOrOut: 'In',
        fileName: 'File1.txt',
      },
      {
        rev: '21/03/19',
        date: '12/12/21',
        time: '12:32:44',
        userName: 'Theo',
        position: 'Position',
        inOrOut: 'In',
        fileName: 'File1.txt',
      },
      {
        rev: '21/03/19',
        date: '12/12/21',
        time: '12:32:44',
        userName: 'Theo',
        position: 'Position',
        inOrOut: 'In',
        fileName: 'File1.txt',
      },
      {
        rev: '21/03/19',
        date: '12/12/21',
        time: '12:32:44',
        userName: 'Theo',
        position: 'Position',
        inOrOut: 'In',
        fileName: 'File1.txt',
      },
      {
        rev: '21/03/19',
        date: '12/12/21',
        time: '12:32:44',
        userName: 'Theo',
        position: 'Position',
        inOrOut: 'In',
        fileName: 'File1.txt',
      },

    ]
  }),
}
</script>
<template>
   <!-- <v-row> -->
      <v-dialog
          v-model="dialog"
          persistent
          width="50vw"
      >
        <template v-slot:activator="{ props }">
          <v-btn
              v-bind="props"
              slot="activator"
              variant="outlined"
              id="ActBtn"
              :density="buttonDensity"
              :size="buttonSize"
          > {{ buttonLabel }}
          </v-btn>
        </template>
        <v-card theme="light">
          <v-card-title class="text-center mt-3">Activity Log </v-card-title>
          <v-card-text>
            <v-container>
              <v-textarea
                id = "messageArea"
                label="Enter message here.."
                variant = "outlined"
                density="default"
                rows = "1"
                auto-grow
                required
                clearable
              ></v-textarea>
              <v-row>

                <v-col>
                 <v-container class="d-flex flex-wrap pa-0">

                   <v-radio-group inline="true">
                     <v-radio
                         label="IN"
                         value="1"
                     ></v-radio>
                     <v-radio label="OUT" value="2"></v-radio>
                   </v-radio-group>
                  <v-checkbox
                      id="checkboxes"
                      density="compact"
                      label="Priority"
                      value="Priority"
                  >
                </v-checkbox>
                  <v-checkbox
                      id="checkboxes"
                      density="compact"
                      label="Immediate"
                      value="Immediate"
                  ></v-checkbox>
                   <v-checkbox
                       id="checkbox3"
                       density="compact"
                       label="Publish to Municipal SitRep Activity Logs"
                       value="Publish to Municipal SitRep Activity Logs"
                       hint="This Activity Log Message,when checked, will publish to the running log of the County as the Lead or supporting Agency engaged in management of this Major Event"
                   >
                   </v-checkbox>
                 </v-container>
                </v-col>
              </v-row>
              <v-file-input
                  prepend-icon=""
                  append-inner-icon="mdi-paperclip"
                  clearable
                  label="File input"
                  variant="outlined"
                  density="default"
              ></v-file-input>
            </v-container>
            <v-container class = "d-flex flex-row-reverse ma-0 pa-0">
               <v-btn
                   color="blue-darken-1"
                   variant="outlined"
                   @click = "goToHome"
               >Submit
               </v-btn> <!-- submit button -->
            </v-container>
          </v-card-text>
           <v-table
                fixed-header
                height="30vh"
                density="comfortable"
            >
             <thead>
              <tr>
                <th class="text-center">
                  Rev
                </th>
                <th class="text-center">
                  Date
                </th>
                <th class="text-center">
                 Time
                </th>
                <th class="text-center">
                  User Name
                </th>
                <th class="text-center">
                  Position
                </th>
                <th class="text-center">
                  IN/OUT
                </th>
                <th class="text-center">
                 Document
                </th>
              </tr>
             </thead>
             <tbody>
              <tr
                  v-for="item in tableData"
                  :key="item.userName"
              >
                <td>{{ item.rev }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.time }}</td>
                <td>{{ item.userName }}</td>
                <td>{{ item.position }}</td>
                <td>{{ item.inOrOut }}</td>
                <td>{{ item.fileName }}</td>
              </tr> <!-- table content -->
             </tbody>
          </v-table>
          <v-card-actions>
            <v-container  class = "d-flex flex-row-reverse ma-2 mt-0 pa-0">
            <v-btn
                max-width="15%"
                id="closeButton"
                color="blue-darken-1"
                variant="outlined"
                @click="dialog = false"
                density="default"
            >
              Close
            </v-btn> <!-- close button -->
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- </v-row> -->
</template>
<style>
#closeButton { width: 10vw }
#messageArea { height: 5vh }
#checkboxes  { height: 1vh }
#ActBtn      {}
</style>