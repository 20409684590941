<script>
export default {
  methods: {
    goToHome: function () {
      let self = this;
      self.$router.push({name: "home"})
    },
    setResReqId: function (rrid) {
      let self = this;
    }
  },
  props: {
    mode: String,
    eid: Number,
    idx: String,
  },
  data: () => ({
    dialog: false,
  }),
 }
</script>
<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="45%"
        id="ResourceRequestResponse"
        theme="light"
    >
      <template v-slot:activator="{ props }">
        <v-btn
            v-if="mode==='map'"
            v-bind="props"
            density="comfortable"
            variant="outlined"
        >Reply</v-btn>
        <a
            v-if="mode==='db'"
            v-bind="props"
            @click="setResReqId"
            class="text-blue-grey-darken-4">
          {{ idx }}
        </a>
      </template>
      <v-card>
        <v-card-title class="text-center mt-3"> Respond to Resource Request </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="text-h7 pa-0 mb-3 ml-1 font-weight-bold"> Request data </v-row>
            <v-container class="text-left mb-0 pa-0 ">
              <v-row>
                <v-col class="ma-1">
                  <p>Severity: </p>
                  <p>Type: </p>
                  <p>Date needed: </p>
                  <p>Return Date: </p>
                  <p>Submitted by: </p>
                  <p>Jurisdiction: </p>
                  <p>Last update: </p>
                </v-col>
                <v-col>
                  <p>Skills needed: </p>
                  <p>Delivery logistics: </p>
                  <p>Point of contact: </p>
                  <p>Situation brief: </p>
                  <p>Resource need description: </p>
                  <p>Additional need: </p>
                </v-col>
              </v-row>
              <v-container>
                <v-row
                    class="text-h7 pa-0 font-weight-bold"> Respond to this resource request </v-row>
              </v-container>
              <v-container class="pl-0">
                <v-col class="pa-0">
                  <v-text-field
                      density="compact"
                      label="User name"
                      required
                      variant="outlined"
                  ></v-text-field>
                </v-col>
                <v-col class="pa-0">
                <v-text-field
                    density="compact"
                    label="User e-mail address"
                    required
                    variant="outlined"
                ></v-text-field>
                </v-col>
                <v-col class="pa-0">
                <v-textarea
                    density="compact"
                    class="mt-2"
                    label="Enter situation brief here..."
                    variant="outlined"
                    rows = "4"
                    auto-grow
                    required
                ></v-textarea>
                </v-col>
              </v-container>
          </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions >
          <v-btn
              class="mb-3 ml-5"
              variant="outlined"
              @click="dialog = false"
              size="default"
              width="47%"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              class="mb-3 mr-5"
              variant="outlined"
              @click="dialog=false"
              size="default"
              width="47%"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<style>
</style>